import React, { useState }  from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal} from "react-bootstrap";
import { faLeaf } from '@fortawesome/free-solid-svg-icons';
import JotformEmbed from 'react-jotform-embed';
import './newsletter.css';
function Pop() {
  const [smShow, setSmShow] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  return (
    <div>
      {!isDisabled && (
        <div className="newsletterBtnWrapper">

        <button
          className="disableBtn"
          onClick={() => {
            setIsDisabled(true);
          }}
        >
          x
        </button>
        <button
          className="newsletterBtn"
          onClick={() => {
            setSmShow(true);
          }}
        >
          Subscribe<FontAwesomeIcon icon={faLeaf} className="footerIcon" />
        </button>

        </div>
      )}

      <Modal
        size="md"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <JotformEmbed src="https://form.jotform.com/220606020731137" />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Pop