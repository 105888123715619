import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './invest.css';
import JotformEmbed from 'react-jotform-embed';
import MetaData from "./metaData/metaData";

const metaData = {
	title: "Invest | Life After Life - Support Regenerative Development", 
	description: "Life After Life is a circular economy startup, we're committed to developing sustainable spaces for equitable communities that prioritize social and environmental revitalization. Our innovative non-profit is focused on transforming underutilized properties into vibrant, remediated spaces that promote healing, social welfare, and health. We're seeking visionary investors who share our values and are interested in supporting a mission-driven company that's changing the face of real estate development. Our 'Invest' page details information about our approach, team, and investment opportunities. As an investor, you can help shape the future of urban development, while also earning a financial return. Join us in our mission to build thriving, resilient communities that benefit people and the planet. Visit our 'Invest' page now to learn more about how you can make a meaningful impact.",
	author: "Life After Life", 
	type: "website", 
	image: "/images/grandparents.webp", 
	url: "lifeafter.life/invest"
}

function Invest() {
    return(
				<Container id="LifeAfterLifeInvestPage">
                <MetaData {... metaData}/>
					<Row className="investBundle">
						<Col xxl={{ order: 1 }} className="investInfo">
							<h1 className="investMainTitle">Invest in Parks</h1>
							<p className="text">
                            Investing in Life After Life means rebuilding habitat for people
                            and nature in our most threatened urban environments. Where
                            conventional conservation projects lack the ability to compete
                            with traditional development, and the public sector lacks the
                            funding to build and maintain the amenities its community
                            desires, find Life After Life.
							</p>
                            <p className="text">
                            Life After Life is raising funds to open 
                            our first parks from which we will be perpetually able
                             to maintain and develop more parks. An investment in your 
                             last rites brings us one step closer to one new acre of park, 
                             in every city, every year, forever.<sup><a href="#fn1" id="ref1">[1]</a></sup>
							</p>
                            <div>
                                <a href="#formBundle" className="investButtonInner" Style="float:left; margin:10px;">Investor Query</a>
                                <a href="/donations" className="investButtonInner" Style="float:left; margin:10px;">Donate</a>
                                <a href="/join" className="investButtonInner" Style="float:left; margin:10px;">Membership</a>
                            </div>

						</Col>
						<Col md={{ order: 2 }} className="grandparentsPicBundle">
							<img
								src="/images/grandparents.webp"
								alt="Celebrating with family by being involved in the services and events Life After Life"
								className="grandparentsPic"
                                Style="width: 75%; padding: 10px 10px 10px 10px; margin: 60px;"
							/>
						</Col>
					</Row>
                    <div className="formBundle" id="formBundle">
                        <JotformEmbed src="https://form.jotform.com/222367199144158"/>
                    </div>
                    <br />
                    <p className="footnote">
                    <sup id="fn1">[1] 1 acre is an estimated average. Exact park acreage per city will vary by year and city. Growth will be exponential as new parks open.<a id="ref_jump" href="#ref1" title="Jump back to footnote 1 in the text.">↩</a></sup>
                       
					</p>
				</Container>

    )

}
export default Invest;
