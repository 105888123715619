import "./App.css";
import React, { useState, useEffect} from "react";
// import { Navbar, Nav, Container } from 'react-bootstrap';
import Navigation from './Components/navbar';
import Home from './Components/home';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Footer from './Components/footer';
import Build from './Components/build';
import Heal from './Components/heal';
import Learn from './Components/learn';
import Wrong from './Components/wrong';
import Whoarewe from './Components/whoarewe';
import Contact from './Components/contact';
import Join from './Components/join';
import Donations from './Components/donations';
import Services from './Components/services';
import Aboutus from './Components/aboutus';
import Invest from './Components/invest';
import Land from './Components/land';
import Volunteer from "./Components/volunteer";
import Events from './Components/events';
import Community from './Components/community';
import Education from './Components/education';
import Careers from './Components/careers';
import Celebration from './Components/celebration';
import Informed from './Components/informed';
import NotFound from './Components/notfound';
import Pop from "./Components/newsletter";
import Articles from './Components/Articles';
import Article from './Components/article';
import Blogs from './Components/blog'
import Store from './Components/store'
import Terms from "./Components/terms";
import Privacy from "./Components/privacy";
import Banner from "./Components/banner"
import SignIn from "./Components/accounts/auth/SignIn"
import SignUp from "./Components/accounts/auth/SignUp"
import Portfolio from "./Components/accounts/auth/portfolio"
import { SidebarProvider } from './Components/accounts/auth/sidebarContext';
import Activity from "./Components/accounts/auth/activity"
import Placeholder from "./Components/accounts/auth/placeholder"
import Sidemenu from './Components/accounts/auth/sidebar'
import { getAuth, onAuthStateChanged } from "firebase/auth"
import { database } from "./repository/firebase"
import { ref, child, get} from "firebase/database";

const helmetContext = {};
function App(props) {

  const auth = getAuth();
  const [userInfo, setUserInfo] = useState(null);
  var authFlag = false;
  const [user, setUser] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false)

  const path = props.location.pathname;
  const classSideMenu = path === '/' || path === '/heal' ? '' : 'app-sidemenu';

  useEffect(()=>{
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        authFlag = true;
        const uid = user.uid;
        const dbRef = ref(database);
        get(child(dbRef, `/accounts/${uid}`)).then((snapshot) => {
            if (snapshot.exists()) {
                setUserInfo(snapshot.val());
                setUser(user);
                setLoggedIn(true);
            } else {
            }
          }).catch((error) => {
            console.error(error);
          });
      } else {
        setUserInfo(null);
      }
    });
    return () => unsubscribe();
  }, [auth])


  return (
    <HelmetProvider context={helmetContext}> 
    <div className="App">
      <Navigation/>
      <div className="content-padding">
        <Banner/>
        <SidebarProvider>
        <div>
          {/* <Sidemenu className={classSideMenu}/> */}
          <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path='/whoarewe' component={Whoarewe} />
            <Route exact path='/build' component={Build} />    
            <Route exact path='/members-benefit' component={Services} />
            <Route exact path="/services" render={() => <Redirect to="/members-benefit" />} />
            <Route exact path='/about-us' component={Aboutus} />
            <Route exact path="/philosophy" render={() => <Redirect to="/about-us" />} />
            <Route exact path='/heal' component={Heal} />
            <Route exact path='/learnmore' component={Learn} />
            <Route exact path='/whatswrong' component={Wrong} />
            <Route exact path='/contactus' component={Contact} />
            <Route exact path='/join' component={Join} /> 
            <Route exact path='/donations' component={Donations} />
            <Route exact path='/store' component={Store} />
            <Route exact path='/privacy' component={Privacy} />
            <Route exact path='/terms' component={Terms} />
            <Route exact path='/invest' component={Invest} />
            <Route exact path='/learn' component={Learn} />
            <Route exact path='/land' component={Land} />
            <Route exact path='/events' component={Events} />
            <Route exact path='/volunteer' component={Volunteer} />
            <Route exact path='/press' component={Articles}/>
            <Route exact path="/Articles" render={() => <Redirect to="/press" />} />
            <Route exact path='/community' component={Community} />
            <Route exact path='/education' component={Education} />
            <Route exact path='/careers' component={Careers} />
            <Route exact path='/celebration' component={Celebration} />
            <Route exact path='/stayinformed' component={Informed} />
            <Route exact path='/blog' component={Blogs} />
            <Route exact path='/login' component={SignIn} />
            <Route exact path='/signup' component={SignUp} />
            <Route exact path='/portfolio' render={(props) => (
              <div className={classSideMenu}>
                <Sidemenu/>
                <Portfolio userInfo={userInfo} user={user} status={loggedIn} {...props} />
              </div>
            )} />
            <Route exact path='/portfolio/activity' render={(props) => (
              <div className={classSideMenu}>
                <Sidemenu/>
                <Activity userInfo={userInfo} user={user} {...props} />
              </div>
            )} />
            <Route exact path='/portfolio/placeholder' render={(props) => (
              <div className={classSideMenu}>
                <Sidemenu/>
                <Placeholder userInfo={userInfo} user={user} {...props} />
              </div>)} />
            
            <Route path='/article/:title' component={Article} />
            <Route exact path="/checkout" render={() => <Redirect to="/join" />} />
            <Route path='*' component={NotFound} />
          </Switch>
        </div>
        </SidebarProvider>
      </div>
      <Pop/>
      <Footer />
    </div>
    </HelmetProvider>  
  );
}

export default withRouter(App);
