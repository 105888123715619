import "./celebration.css";
import { Container} from 'react-bootstrap'
const mountains = '/images/mountains.webp'

function Celebration() {
    return(
        <Container fliud className="celebrateContainer">
            <head>
                <title>Celebrations of Life</title>
            </head>
            <body id="celebration_body">
            <div clasName="celebrateHeader">
                        
            <h1 className="celebrateTitle">Coming soon: Celebrations of Life</h1>
                <div className="celebrateText">
                 
                    <span className="celebrateSubtext">
                    {/* <h1 className="celebSubtitle">Coming Soon at Life After Life</h1> */}
                    <img src={mountains} alt="Mountain landscape preserved by Life After Life" className="mountains" />



                    
    
                    </span>
                </div>
        </div>
        </body>
        </Container>
       
    )

}

export default Celebration;