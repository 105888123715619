import { Link, NavLink, withRouter } from 'react-router-dom';
import { getAuth, signOut } from "firebase/auth";
import { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent
} from 'react-pro-sidebar';
import {
    FaAngleDoubleLeft,
    FaAngleDoubleRight,
    FaUser
  } from "react-icons/fa";
// import Profile from './profile.js'
import SignIn from './SignIn'
import './sidebar.css';
import { useSidebar } from './sidebarContext';

function Sidemenu(props) 
  {
    const { isSidebarCollapsed, toggleSidebar } = useSidebar();
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const accountPages = ['/portfolio/activity', '/portfolio/placeholder', '/portfolio'];

    const activity = '/images/activity.webp'
    const profile = '/images/profile.webp'
    const exit = '/images/noun-exit.webp'

    const handleCollapsedChange = () => {
        setCollapsed(!collapsed);
        toggleSidebar(!isSidebarCollapsed);
      };

    const isCollapsed = () => {
      if (isSmallScreen) {
        return isSidebarCollapsed;
      }
      else{
        return collapsed;
      }
    }

    // const mobileToggleSidebar = () =>{
    //   if (isSmallScreen){
    //     console.log(isSidebarCollapsed);
    //     toggleSidebar(true);
    //     setCollapsed(true);
    //   }
    // }
    
    const handleLogOut = () => {
        const auth = getAuth();
        signOut(auth).then(() => {
            window.location.href = "/";
        }).catch((error) => {
            console.log(error);
        });
    }
    useEffect(() => {
      const handleResize = () => {
        setIsSmallScreen(window.innerWidth < 600);
      };

      handleResize();
      window.addEventListener('resize', handleResize);


      return () => {
        window.removeEventListener('resize', handleResize);
      };
  }, []);

    useEffect(() => {
      const closeSidebarOnClickOutside = (event) => {
        const sidebar = document.querySelector('.pro-sidebar-inner');
        const isPage = event.target.closest('.profile-active-link');

        if (sidebar && ((!sidebar.contains(event.target) && !event.target.classList.contains('disableBtn'))|| isPage) && isSmallScreen && !isSidebarCollapsed) {
          console.log(event.target);
          toggleSidebar(false);
          setCollapsed(false);
        }
      };

      document.addEventListener('click', closeSidebarOnClickOutside);

      return () => {
        document.removeEventListener('click', closeSidebarOnClickOutside);
      };
    }, [toggleSidebar]);

    if (!accountPages.includes(props.location.pathname)){
        return null;
    }
    return (
        <ProSidebar
          collapsed={isCollapsed()}
          width={isSmallScreen ? '70%' : ''}
        >
          {/* Header */}
          <SidebarHeader className={`sidebar-header-${isCollapsed()  ? 'collapsed' : 'flex'}`}>
            <Menu>
              {isCollapsed() ? (
                <MenuItem
                  style={{ color: "#F8FAFE"}}
                  icon={<FaAngleDoubleRight />}
                  onClick={handleCollapsedChange}
                ></MenuItem>
              ) : (
                <MenuItem
                  style={{ color: "#F8FAFE" }}
                  suffix={<FaAngleDoubleLeft style={{ marginTop: '10px' }}/>}
                  onClick={handleCollapsedChange}
                >
                  <div className='sidebar-header-text'> Dashboard</div>
                </MenuItem>
              )}
            </Menu>
          </SidebarHeader>
          {/* Content */}
          <SidebarContent>
            <Menu>
            {
                isCollapsed() ? (
                <MenuItem 
                  className="sidebar-flex-item-collapsed">
                    <img src={activity} alt="activity image" className='portfolio-icon'/> 
                </MenuItem>
                ):(
                <MenuItem 
                  className="sidebar-flex-item">
                    <img src={activity} alt="activity image" className='portfolio-icon'/>
                    <NavLink to='/portfolio/activity' activeClassName='profile-active-link' style={{ textDecoration: 'none', color: '#f7faff' }}>Activity</NavLink>
                </MenuItem>
                )
            }
            {
                isCollapsed() ? (
                <MenuItem 
                  className="sidebar-flex-item-collapsed">
                   <img src={profile} alt="profile image" className='portfolio-icon'/>
                </MenuItem>
                ):(
                <MenuItem 
                  className="sidebar-flex-item">
                    <img src={profile} alt="profile image" className='portfolio-icon'/>
                    <NavLink to="/portfolio" activeClassName="profile-active-link" style={{ textDecoration: 'none', color: '#f7faff' }}>Profile</NavLink>
                </MenuItem>
                )
            }
            {/* {
                collapsed ? (
                <MenuItem className="sidebar-flex-item-collapsed">
                   <img src={profile} alt="profile image" className='portfolio-icon'/>
                </MenuItem>
                ):(
                <MenuItem className="sidebar-flex-item">
                    <img src={profile} alt="profile image" className='portfolio-icon'/>
                    <NavLink to="/portfolio/placeholder" activeClassName="profile-active-link" style={{ textDecoration: 'none', color: '#f7faff' }}>Placeholder</NavLink>
                </MenuItem>
                )
            } */}
            
            </Menu>
          </SidebarContent>
          {/* Footer */}
          <SidebarFooter className="sidebar-footer">

        {
            isCollapsed() ? (
            <MenuItem className="sidebar-footer-item-collapsed">
                <img src={exit} alt="exit image" className='exit-icon'/>
            </MenuItem>
            ):(
            <div className="sidebar-btn-wrapper" style={{ padding: "16px" }}>
                <Button
                className="sidebar-btn"
                style={{ cursor: "pointer", 
                borderColor: "#F7FAFF", }}
                onClick={handleLogOut}>
                <span>Log Out</span>
                </Button>
            </div>
            )
        }
          </SidebarFooter>
        </ProSidebar>
      );
  };
  
  export default withRouter(Sidemenu);