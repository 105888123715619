import { getAuth, onAuthStateChanged, updatePassword } from "firebase/auth";
import { database } from "../../../repository/firebase";
import React, { useState, useEffect } from "react";
import { ref, onValue, child, get, query, remove, set} from "firebase/database";

import './portfolio.css';
import { handlePhoneNumberFormat } from "../../../utils/phoneNumberFormatter";
import ShowPassword from "../auth/ShowPassword";
import { useSidebar } from './sidebarContext';

const Portfolio = ({ userInfo, user, status, ...props })=> {
    const auth = getAuth();
    const profile = '/images/profile.webp'
    const badge = '/images/badge.webp'

    const [isSmallScreen, setIsSmallScreen] = useState(false);
    

    // console.log(user);
    const [updatedUserInfo, setUpdatedUserInfo] = useState(userInfo);

    const [showPassword, setShowPassword] = useState(false);

	const [passwordsMatch, setPasswordsMatch] = useState(false);

    const [accountPasswordError, setAccountPasswordError] = useState("");

	const [accountEmailError, setAccountEmailError] = useState("");

    const [passwordObject, setPasswordObject] = useState({
		password: "",
		confirmPassword: "",
	});

    const [userState, setUserState] = useState({
		firstName: "",
		lastName: "",
		gender: "",
		phone: "",
		address: "",
		city: "",
		state: "",
		emilAddress: "",
	});

    const onHandlePasswordChange = (e) => {
		setPasswordObject({ ...passwordObject, [e.target.name]: e.target.value });
	};

    const handleClearErrors = () => {
		setAccountEmailError("");
		setAccountPasswordError("");
	};

    const dbRef = ref(database);
    // const [loggedIn, setLoggedIn] = useState(false)
    // setLoggedIn(status);
    // console.log(status);
    const [edit, setEdit] = useState(false);

    const returnToSignIn = () => {
        // console.log(status);
        if (!status) {
            // console.log("should not enter here")
            // window.location.href = "/login";

        }
    }

    const updateDatabase = async (db, user) => {
        get(child(db, '/submission')).then((snapshot) => {
            if (snapshot.exists()){
                snapshot.val()
                //iterate over all new submissions
                snapshot.forEach((childSnapShot) => {
                    childSnapShot.forEach((submission) => {
                        var submission = submission.val();
                        var userEmail = submission.email;
                        // console.log(userEmail);
                        // console.log(submission.donation_amount)
                        const donationAmount = JSON.parse(submission.donation_amount);

                        //if email matches user email, it is a new submission by this user. Append donation info into accounts/uid/donations and delete submission
                       if (user.email == userEmail){
                            // console.log("emailmatched");
                            const donationData = {
                                donationDate: submission.donation_date,
                                donationAmount: donationAmount.total,
                            };

                            const userDonationPath = `/accounts/${user.uid}/donations/${submission.submission_id}`;
                            
                            const submissionRef = `submission/${submission.submission_id}`;

                            set(ref(database, userDonationPath), donationData)
                            .then(() => {
                                // console.log("New donation added to the user's account successfully!");
                                set(ref(database, submissionRef), null)
                                .then(() => {
                                // console.log(`Donation with key ${submission.submission_id} deleted successfully.`);
                                })
                                .catch((error) => {
                                console.error(`Error deleting donation with key ${submission.submission_id}:`, error);
                                });
                            })
                            .catch((error) => {
                                console.error("Error adding new donation:", error);
                            });
                       }
                    })
                })
            }
        })
    }

    useEffect(() => {
        if (user) {
            if (!auth.currentUser.emailVerified){
                window.location.href = "/login";
            }
            // console.log("signed in")
            onHandleUpdateUserInfo();
            updateDatabase(dbRef, user);
            updatePassword(user, passwordObject.password).then(() => {
                console.log("password udpated");
              }).catch((error) => {
                console.log(error);
              });
        } else {
            // console.log("not signed it")
            // window.location.href = "/login"
            setTimeout(returnToSignIn, 1000);
        }
        if (passwordObject.password === passwordObject.confirmPassword) {
			setPasswordsMatch(true);
		} else {
			setPasswordsMatch(false);
		}
      }, [user, passwordObject.password, passwordObject.confirmPassword]);
    
    const onHandleChangeForUser = (e) => {
        const path = `/accounts/${user.uid}/${e.target.name}`
        if (e.target.name == 'password'){
        }else if (e.target.name == 'phone'){
            setUserState({
				...userState,
				[e.target.name]: handlePhoneNumberFormat(e.target.value),
			});
            set(ref(database, path), handlePhoneNumberFormat(e.target.value))
        }else{
            setUserState({
                ...userState,
                [e.target.name]: e.target.value
            });
            set(ref(database, path), e.target.value)
        }
	};

    const onHandleUpdateUserInfo = () =>{
        const uid = user.uid;
        get(child(dbRef, `/accounts/${uid}`)).then((snapshot) => {
            if (snapshot.exists()) {
                console.log(snapshot.val())
                setUpdatedUserInfo(snapshot.val());
            } else {
            }
            }).catch((error) => {
            console.error(error);
            });
    }

    useEffect(() => {
        const handleResize = () => {
          setIsSmallScreen(window.innerWidth < 600);
        };
    
        // Initial check on mount
        handleResize();
    
        // Add event listener for window resize
        window.addEventListener('resize', handleResize);
    
        // Cleanup: Remove the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);
    
    return (false) ? (
        null
    ):(
        <div className="portfolio-wrapper">
        {/* {console.log(status)} */}
        {updatedUserInfo ? (
            <div style={{width: "100%", 'margin-top':'56px'}}>
                <div className="ellipse-portfolio-wrapper">
                    <div className="ellipse-portfolio">
                        <img src={profile} alt="profile image" className="ellipse-portfolio-img"/>
                    </div>
                    <div className="name-portfolio">{updatedUserInfo.firstName}</div>
                </div>
                <div className='portfolio-info-wrapper'>
                 
                {!edit ? (
                    <div>
                        <span className='portfolio-info-heading'>Basic Information</span>
                        {/* <span>
                            <button className="profile-button" type="button" onClick={()=>{console.log("Edit button clicked");setEdit(!edit)}}> edit</button>
                        </span> */}
                        <div className='portfolio-info-content'>{updatedUserInfo.firstName}</div>
                        <div className='portfolio-info-content'>{updatedUserInfo.emailAddress}</div>
                        <div className='portfolio-info-content'>{updatedUserInfo.address}</div>
                        <div className='portfolio-info-content'>Password</div>
                    </div>
                ):(
                    <div>
                        <span className='portfolio-info-heading'>Basic Information</span>
                        <span>
                            <button className="profile-button" type="button" onClick={()=>{console.log("Edit button clicked");setEdit(!edit); onHandleUpdateUserInfo()}}> save</button>
                        </span>
                        <input className='portfolio-info-content'
                            type="text"
                            name="firstName"
                            value={userState.firstName}
                            onChange={(e) => onHandleChangeForUser(e)}
                            autoFocus
                        />
                        <label className="label-SignUp">FirstName</label>

                        <div className='portfolio-info-content-inactive'>{userInfo.emailAddress}</div>
                        <label className="label-SignUp">Email</label>

                        <input className='portfolio-info-content'
                            type="text"
                            name="address"
                            value={userState.address}
                            onChange={(e) => onHandleChangeForUser(e)}
                            autoFocus
                        />
                        <label className="label-SignUp">address</label>

                        <input className='portfolio-info-content'
                            type={showPassword ? "text" : "password"}
                            name="password"
                            value={passwordObject.password}
                            onChange={(e) => onHandlePasswordChange(e)}
                            />
                        <label className="label-SignUp">Password*</label>

                        <input className='portfolio-info-content'
                            type={showPassword ? "text" : "password"}
                            name="confirmPassword"
                            value={passwordObject.confirmPassword}
                            onChange={(e) => onHandlePasswordChange(e)}
                        />
                        <label className="label-SignUp">Confirm Password*</label>


                        <ShowPassword
                            setShowPassword={setShowPassword}
                            showPassword={showPassword}
                        />

                        <div style={{ color: passwordsMatch ? "green" : "red" }}>
                            {passwordObject.confirmPassword.length > 0 && (
                                <p>{passwordsMatch ? "Passwords Match" : "Passwords Don't Match"}</p>
                            )}
                        </div>

                        <p>{accountPasswordError}</p>
                    </div>
                    )}
                
                <div className="portfolio-badge-wrapper">
                    <img src={badge} alt="badge image" className="portfolio-badge-img"/>
                    <div className="portfolio-badge-name">SUPPORTER Tier!</div>
                </div>
                    
                </div>
                </div>
        ) : (
            // User is not signed in, display appropriate content
            // window.location.href = "/login"
            console.log("not logged in")
        )}
        </div>
    );
}


export default Portfolio;
