import React from 'react';
import "./article.css";
import articleText from "./articles/articles.json";
import { Container } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { FaFacebook, FaTwitter, FaLinkedin, FaTumblr,FaPinterest, FaEnvelope, FaLink} from 'react-icons/fa';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, TumblrShareButton, PinterestShareButton, EmailShareButton} from 'react-share';

const copyToClipboard = () => {
    const url = window.location.href; // get the URL to copy
    navigator.clipboard.writeText(url).then(() => {
        alert('URL copied to clipboard!');
      });
  }

const socialShare = <div>
    <FacebookShareButton className='share-button' url={window.location.href}>
        <FaFacebook size={32} round />
    </FacebookShareButton>
    <TwitterShareButton className='share-button' url={window.location.href}>
        <FaTwitter size={32} round />
    </TwitterShareButton>
    <LinkedinShareButton  className='share-button' url={window.location.href}>
        <FaLinkedin size={32} round />
    </LinkedinShareButton> 
    <TumblrShareButton  className='share-button' url={window.location.href}>
        <FaTumblr size={32} round />
    </TumblrShareButton>   
    <PinterestShareButton  className='share-button' url={window.location.href}>
        <FaPinterest size={32} round />
    </PinterestShareButton> 
    <EmailShareButton  className='share-button' url={window.location.href}>
        <FaEnvelope size={32} round />
    </EmailShareButton>
    <FaLink className='share-button' size={32} round onClick={copyToClipboard}/>          
</div>

class Article extends React.Component{
    componentDidMount(){
        if (isNaN(this.props.match.params.title)){
            var index = 0;
            for (let i = 0; i < articleText.articles.length; i++) {
                if (articleText.articles[i].title == this.props.match.params.title){
                    index = i;
                }
            }   
            const articleBody= articleText.articles[index].paragraphs;
            document.getElementById("paragraph").innerHTML = articleBody.join("<p><\p>");
            }
    }
    
    redirect(){
        const index = articleText.articles.length - this.props.match.params.title - 1;
        const title = articleText.articles[index].title;
        return <Redirect to={`/article/${title}`}/>;
    }

    render()
    {
        if (!isNaN(this.props.match.params.title)){
            return this.redirect();
        }
        var index = -1;
        for (let i = 0; i < articleText.articles.length; i++) {
            if (articleText.articles[i].title == this.props.match.params.title){
                index = i;
            }
          }
        
        var id = index;
        var next = +index - 1;
        var prev = +index + 1;
        const articleBody= articleText.articles[id].paragraphs;
        const body = <p id="paragraph">{articleBody}</p>;
        console.log(id);
        const refer = articleText.articles[id].reference.map((r) => <li><a href={r} target = "_blank" rel="noreferrer">{r}</a></li>)

        var previous_active = ""
        var next_active = ""
        if (articleText.articles.length -  index - 1 == 0){
            previous_active = " btn__disabled";
            prev = 0;
        }

        if (articleText.articles.length -  index - 1 == articleText.articles.length - 1){
            next_active = " btn__disabled"
            next = articleText.articles.length - 1;
        }
        
        return (
            <Container className='page-wrapper'>
                <head>
                    <title>
                        Life after Life articles
                    </title>
                </head>
                <body>
                    <h1 className='article-head'>{articleText.articles[id].heading}</h1>
                    <p className='article-date'>{articleText.articles[id].date}</p>
                    <img src = {articleText.articles[id].img} alt = {articleText.articles[id].alt} />
                    <div className='article-body'>
                        {body}
                    </div>
                    <h3>References: </h3>
                    <ul>
                        {refer}
                    </ul> 
                    {socialShare}
                    <div className='btn-wrapper'>
                        <a className= {"page-btn" + previous_active} href={"/article/" + articleText.articles[prev].title}  >&laquo; Previous</a>
                        <a className= "page-btn" href="/blog"  >Back to Blogs</a>
                        <a className= {"page-btn" + next_active} href={"/article/" + articleText.articles[next].title}   >Next &raquo;</a>
                    </div>
                    
                </body>
            </Container>
        )
    };
}

export default Article;