import React from 'react';
import { Container } from 'react-bootstrap';
import './careers.css'

import { Row } from 'react-bootstrap'


function Careers() {
    return(
        <Container>
            <head>
                <title>Careers</title>
            </head>
            <body id="career_body">
              <div className="careersPage">
            <h1 className="careers-header">Careers at Life After Life</h1>
            <div className="mission-container">
                <p>We believe our choices to live sustainably should end with the ability to give One Last Good Deed.
                     That is why we are building, and why we want to invite you to build with us.</p>
            </div>
            <Row className="card-container">
            <div class="row">
            <div class="col-lg-6">
                <div class="career-card card">
                    <img class="card-img-top" src="./images/conservation.webp" alt="Nurturing Nature: Individuals dedicatedly caring for the environment, promoting sustainability, biodiversity, and a greener future."/>
  
                    <div class="card-body">
                        <h2 class="career-card-title card-title">Volunteer</h2>
                        <p class="card-text">
                            Being a volunteer at Life After Life means leaving the earth better than 
                            when you found it. As a volunteer, you will contribute to the preservation of nature 
                            and the conservation of life through the variety of volunteer services. Spend your time amongst 
                            nature as you help us garden, find land, commemorate life, and spread the word of our mission of sustainability. 
                            Best of all, you will be making a difference along side others for a better future.
                            Enroll with a friend or meet new people through our volunteer opportunities today!
                            <br></br><br></br>

                            <a href="/volunteer"><button className="volunteerBtn">Learn More</button></a>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="card career-card">
                    <img class="card-img-top" src="./images/backtonature.webp" alt="Nature enthusiasts observing diverse plants, fostering biodiversity conservation and eco-awareness."/>
  
                    <div class="card-body">
                        <h3 class="career-card-title card-title">Internship</h3>
                        <p class="card-text">
                            Join us in collaboration with fellow environmentalists as we further 
                            our mission for a greener and more sustainable future. As an intern, you 
                            will gain hands on experience in making a direct impact at Life After Life while sharpening
                            your skills in collaboration, leadership, and problem solving. Internships 
                            at Life After Life offer opportunities in fields such as sustainability, tech, 
                            urban planning, marketing, and death care services. It's never too early to make a difference, so why not
                            do it as an intern with us, at Life After Life!
                            <br></br><br></br>
                            <a href="/contactus"><button className="internBtn">Inquiry</button></a>
                        </p>
                    
                    </div>
                 
                </div>
            
            </div>
        </div>
     
     
         </Row>
    
       
        </div>
        <div>
  
            <div>
            
                <div className="footerCareer">
                <h4 className="careers-header">Come Build A Future with Us!</h4>
                <p className="mission-container">Life After Life is pursuing a new paradigm of final resting place-- one that restores habitat to the earth 
                    rather than destroying it.
                    <br></br><br></br>
                    Inspired by the process of nature itself, Life After Life is a new model of regenerative development for the 
                    future of people and the planet. Reflected in out Motto, 'One Last Good Deed' - Life After Life enables our customers the 
                    ability for a loved one's loss to be part of something positive for those they leave behind. We 
                    provide the opportunity for a person's last choice to contribute to healing the planet- leaving things better 
                    than they came.
                    </p>
                </div>
            </div>
            </div>
            </body>
        </Container>
      
    )

}
export default Careers;