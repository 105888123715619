import React, { useState } from "react";
import "./home.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSeedling } from "@fortawesome/free-solid-svg-icons";
import { faCloud } from "@fortawesome/free-solid-svg-icons";
import { faHandHoldingHeart } from "@fortawesome/free-solid-svg-icons";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { faTree } from '@fortawesome/free-solid-svg-icons';
import { Container, Row, Col } from "react-bootstrap";
import MetaData from "./metaData/metaData";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const familyPic = "/images/Life After Life - Celebration.webp";
const park = "/images/Life After Life - Park.webp";
const lake = "/images/Life After Life - Lifecycle.webp";
const cremation ="/images/Life After Life - Why Build - Traditional Cemetery Wasteful.webp"
const acceliCity = './images/accelicity.webp'
// const landscape = './images/landscape_architecture_magazine.webp'
const landscape = './images/LAMLogo.webp'
const washington = './images/washington_postlogo.webp'
const cornell = './images/Cornell_University_Logo.webp'
const moonshot = './images/Moonshot-Competition-home.webp'
const nybpc = './images/NYBPC_home.webp'
const brownfields = './images/brownfields.webp'
const twincities = '/images/twincities.webp'


const metaData = {
	title: "Home | Life After Life", 
	description: "Secure a sustainable final resting place plot while supporting the development of new parks where nature is needed most. Life After Life's modern memorial parks heal communities using conservation cemetery and green natural burial practices, remediating damaged ecosystems and restoring habitats with the endangered trees, plants, and animals that call this land home too.", 
	author: "Life After Life", 
	type: "website", 
	image: "/images/Life After Life Logo Blended 6 Large size smaller file.webp", 
	url: "lifeafter.life"
}

const sliderData = [
	{
		link: "https://www.twincities.com/2023/08/30/tcaap-parcel-arden-hills-for-sale-natural-cemetery/",
		img: twincities,
		classname: "home-carousel",
		wrapperClassName: "",
	},
	{
		link: "https://business.cornell.edu/hub/2021/06/22/at-a-glance-2021-sha-celebration-week/",
		img: cornell,
		classname: "home-carousel-large",
		wrapperClassName: "carousel-item-wrapper",
	},
	{
		link: "http://bt.royle.com/publication/?m=44306&i=785366&p=26&ver=html5/",
		img: landscape,
		classname: "home-carousel"
	},
	{
		link: "https://www.washingtonpost.com/climate-environment/2023/01/31/green-funeral-options-cremation-burial/",
		img: washington,
		classname: "home-carousel",
		wrapperClassName: "",
	},
	{
		link: "https://leadingcities.org/accelicity/257e68d7-56e0-4346-9cd3-f7f2fc66b407",
		img: acceliCity,
		classname: "home-carousel",
		wrapperClassName: "",
	},
	{
		link: "https://leadingcities.org/accelicity/257e68d7-56e0-4346-9cd3-f7f2fc66b407",
		img: brownfields,
		classname: "home-carousel",
		wrapperClassName: "",
	},
	{
		link: "https://www.lifechanginglabs.com/network/",
		img: moonshot,
		classname: "home-carousel-large",
		wrapperClassName: "carousel-item-wrapper",
	},
	{
		link: "https://nybpc.org/news/2021/5/11/winners-of-2021-new-york-business-plan-competition-announcednbsp",
		img: nybpc,
		classname: "home-carousel",
		wrapperClassName: ""
	}
]

function SampleNextArrow(props) {
	const { className, style, onClick } = props;
	return (
	  <div
		className={className}
		style={{ ...style, display: "block", "margin-right":"4%" }}
		onClick={onClick}
	  />
	);
  }

  function SamplePrevArrow(props) {
	const { className, style, onClick } = props;
	return (
	  <div
		className={className}
		style={{ ...style, display: "block", "margin-left":"4%", 'z-index':"999"}}
		onClick={onClick}
	  />
	);
  }

const settings = {
	centerMode: true,
	dots: true,
	infinite: true,
	speed: 500,
	slidesToShow: 4,
	slidesToScroll: 1,
	adaptiveHeight: true,
	maxHeight: '300px',
	arrows: true,
	nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };

function Carousel(){
	return (
		<div style={{"background-color": "#808000", width: "100%"}}>
		<Slider {...settings}>
		{sliderData.map((data) => (
			<div>
				<a href={data.link} target="_blank" className={data.wrapperClassName}>
					<img src={data.img} className={data.classname}/>
				</a>
			</div>
		))}
		</Slider>
		</div>
	)
}


function Home() {
	let isShown = null;
	if (window.matchMedia("(max-width: 700px)").matches) {
		isShown = false;
		// alert("matches");
	} else {
		isShown = true;
	}

	return (
		<div>
			<MetaData {... metaData}/>
			<div className="homePage">
				<video
						className="video"
						width="100%"
						height="620"
						frameBorder="0"
						// padding="0"
						autoplay="autoplay" 
						loop="loop"
						defaultMuted
						playsinline 
						playsInline
						muted>
						<source
							src= "/videos/video_trimmed.mp4"
							// src="https://firebasestorage.googleapis.com/v0/b/life-after-life-1601e.appspot.com/o/trimmedLandingVideo.mp4?alt=media&token=cd0b3134-a46d-45ba-91fd-a7d7eddb491f"
						/>
				</video>
				<div className="hero-container" >
					<div className="introText">
						<h1>
							Life, after life.
						</h1>
						<p>
							Build a park &amp; create a legacy - all with one decision
						</p>
						<div className="introButtons">
							<div className="introButtonsCluster">
								<button className="homeBtn"><a href="/join" style={{ textDecoration: 'none', color: '#F7FAFF' }}>Become a Member</a></button>
								<button className="homeBtn"><a href="/build" style={{ textDecoration: 'none', color: '#F7FAFF' }}>Why Build?</a></button>
							</div>
						</div>
					</div>
				</div>
				<Container>
						<Row className="celebrateBundle">
							<Col xs={{ span: 12 }} md={{ order: 1 }} className="celebrateInfo">
								<h2 className="celebrateMain-title-left">Living Memorials</h2>
								<p className="text">
									Every good story deserves a good ending. Let your legacy be a gift to those you leave behind. With Life After Life, your final resting place is a park, and your biography is eternalized.
								</p>
								<a href="/heal" className="learnButtonInner">How We Heal</a>
							</Col>
							<Col  xs={{ span: 12 }} md={{ order: 2 }} className="familyPicBundle">
							<img src={park} alt="Ideal Park & Public Space by Life After Life: A sustainable landscape showcasing urban greenery, inclusivity, and community engagement." className="park-Pic" />
							</Col>
						</Row>
					
					<Row className="celebrateBundle">
						<Col xs={{ span: 12, order: 1 }} md={{ order: 12 }} className="familyPicBundle">
								<img
									src={familyPic}
									alt="Families Embrace Life After Life: Active involvement in meaningful services and events, cherishing memories and fostering togetherness."
									className="familyPic"
								/>
						</Col>
						<Col xs={{ span: 12, order: 12 }} md={{ order: 1 }} className="celebrateInfo">
							<h2 className="celebrateMain-title">Celebrate Life!</h2>
							<p className="celebrateParagraph-right text">
								Life After Life is a place where lives are celebrated - an
								oasis of positive endings and new beginnings in the wonder of the
								natural processes of succession.
							</p>
							<a href="/learnmore" className="learnButtonInner celebrateParagraph-right">Learn More</a>
						</Col>
					</Row>
					<Row className="celebrateBundle">
						<Col xs={{ span: 12, order: 1 }} md={{ order: 2 }} className="familyPicBundle">
								<img src={cremation} alt="Wasteful Traditional Cemetery: A solemn resting place lacking sustainability, calling for eco-friendly alternatives and green practices" className="lake-img" />
						</Col>
						<Col xs={{ span: 12, order: 12}}  md={{ order: 1 }} className="celebrateInfo">
							<h2 className="celebrateMain-title-left">One Last Good Deed </h2>
							<p className="text">
							Recent environmental studies have exposed traditional cemeteries as among 
									the most toxic of landscapes of our era due to their pollution and intensive use 
									of resources. There’s now an option for your last good deed to help 
									the environment, not hurt it.
							</p>
							<a href="/whatswrong" className="learnButtonInner">What's Wrong?</a>
						</Col>
					</Row>
					
					<Row className="philBundle">
						<Col>
							<div className="philInfo">
								<h2 className="philMain-title">Building a Better Tomorrow, Together</h2>
								<p className="philParagraph text">
									Life After Life is a new paradigm of final resting place
									-- one that restores habitat to the earth rather than destroying
									it. By choosing Life After Life, each of our patrons directly
									heals the world by building new native ecosystem park space.{" "}
								</p>
								<p className="philParagraph text">
									Let's make sure your last choice is a good one. Join us today, and build a better world for tomorrow.{" "}
								</p>
								<br /><a href="/about-us" className="philBtn">About Us</a>
							</div>
							<Col className="LakePic reorder">
							<img src={lake} alt="Tranquil Lake View: Nature's beauty reflected in a serene and peaceful landscape, inviting contemplation and harmony at Life After Life" className="lake-img" />
							</Col>
						</Col>
					</Row>
					</Container>
					{/* <div className='as-seen-in-wrapper'> */}
						<div className="as-seen-in-title ">As Seen In</div>
						<Carousel/>
					<Container>
					<h2 className="lal-services">Member Benefits</h2>

				
					 <div className="serve-container">
					 <div className="icon-wrapper"><FontAwesomeIcon icon={faTree}  className='trIcon symbol'/> </div>
							<span className="serviceText">
								<h3 className="subtitle">Modern Memorial Parks</h3>
								<p>
								Our Modern Memorial Parks are a sustainable end-of-life destination where the remediation of dilapidated 
								land into biodiverse, native-ecosystem, parks is powered by patrons who choose Life After Life as their final resting place.
								By becoming a Life After Life member, a plot is automatically reserved for you within one of our parks.
								</p>
							</span>
						</div>


						<div className="serve-container">
						<div className="icon-wrapper"><FontAwesomeIcon icon={faSeedling} className="scpIcon symbol" /></div>
							<span className="serviceText">
								<h3 className="subtitle">Nature Burials</h3>
								<p>
									Our Nature Burials do not use embalming fluid, ornate caskets, or
									burial vaults. Instead, remains are placed bare directly into
									the earth, within a biodegradable vessel, or in a simple
									compostable burial shroud, allowing the body to decompose
									naturally. This practice allows for natural reduction without
									polluting the ecosystem, returning the body's nutrients to the trees and 
									plants selected by the patron. All Life After Life memberships secure nature burial services.
								</p>
							</span>
						</div>

						<div className="serve-container">
						<div className="icon-wrapper"><FontAwesomeIcon icon={faCloud} className="cmIcon symbol" /></div>
							<span className="serviceText">
								<h3 className="subtitle">Cloud Memorials</h3>
								<p>
									Our Cloud Memorials immortalize personal histories. Visitors to the memorial park
									can experience an even deeper interaction by exploring a shared scrapbook of family
									and friend uploaded media. All Life After Life members recieve access to our Cloud Memorials.
								</p>
							</span>
						</div>

						<div className="serve-container">
						<div className="icon-wrapper"><FontAwesomeIcon
								icon={faHandHoldingHeart}
								className="pcIcon symbol"
							/></div>
							<span className="serviceText">
								<h3 className="subtitle">Plot Customization &amp; Selection</h3>
								<p>
									Our Plot Customization & Selection Interface allows customers to personalize
									their plot sections with horticulture that meet their meets their individual desires. All Life After Life members recieve access to our Plot Customization & Selection and other preplanning tools.
								</p>
							</span>
						</div>

						<div className="serve-container">
						<div className="icon-wrapper"><FontAwesomeIcon icon={faMapMarkerAlt} className="gslIcon symbol" /></div>
							<span className="serviceText">
								<h3 className="subtitle">GPS Spot Locating</h3>
								<p className="gps">
									{" "}
									Our GPS Spot Locating makes for easy wayfinding. Visitors can search
									for and return to the exact location of the burial of their loved ones using
									their mobile devices. GPS Spot Locating Services are provided for all Life After Life patrons.

								</p>
							</span>


						</div>
						<div>

							<a href="/services" className="homelearnMoreBtn">Learn More</a>

						</div>
				</Container>
			</div>
		</div>
	)
}


export default Home;
