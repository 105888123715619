import React from 'react';
import { Container } from 'react-bootstrap';
import './community.css'


function Community() {
    return(
        <Container className="philo-container">
        <head>
            <title>
                Community
            </title>
        </head>
        <body>
        <div className="community-background">
            <div className="community_Main">
                <h1 className="community_heading">Coming Soon: Community</h1>
                <p className="community_context"> Life After Life is building modern memorial parks in urban spaces. If you’re like us and love nature and sustainability, then this IS the last place you’ll want to be.
                </p>
            </div>
  
        </div>
        </body>

    </Container>

    )

}
export default Community;