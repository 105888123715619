export function handlePhoneNumberFormat(phoneNumberString) {
	if (!phoneNumberString) return phoneNumberString;

	const actualPhoneNumber = phoneNumberString.replace(/[^\d]/g, "");

	const actualPhoneNumberLength = actualPhoneNumber.length;

	if (actualPhoneNumberLength < 4) return actualPhoneNumber;

	if (actualPhoneNumberLength < 7) {
		return `(${actualPhoneNumber.slice(0, 3)}) ${actualPhoneNumber.slice(3)}`;
	}

	return `(${actualPhoneNumber.slice(0, 3)}) ${actualPhoneNumber.slice(
		3,
		6
	)}-${actualPhoneNumber.slice(6, 10)}`;
}
