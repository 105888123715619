import React from 'react'
import './aboutus.css'
import { Container } from 'react-bootstrap'
import MetaData from "./metaData/metaData";

const metaData = {
	title: "About Us | Life After Life - One Last Good Deed", 
	description: "At Life After Life Foundation, our philosophy is simple: to provide sustainable and compassionate end-of-life services that celebrate life and protect the environment. Explore our core values of sustainability, placemaking, and inclusivity. We believe that deathcare can be a positive and transformative experience that honors the individual and while giving back to those they leave behind. Our commitment to a circular economy means that we strive not just to reduce the environmental impact of death and burial practices but to leave a net- positive impact on the ecosystem. We prioritize the creation of beautiful and accessible urban green spaces that foster biodiversity and local community engagement. Our philosophy is rooted in equitable green space access and inclusivity, ensuring that everyone has access to dignified and affordable end-of-life services. Discover our innovative approach to deathcare and learn why LAL’s Parks are the leader in the green funeral services market. Visit Life After Life’s philosophy page here.",
	author: "Life After Life", 
	type: "website", 
	image: "/images/Life After Life - Lifecycle of the Ecosystem.webp", 
	url: "lifeafter.life/aboutus"
}

function Aboutus() {
    return (
          <Container id="LifeAfterLifePhiloPage">
            <MetaData {... metaData}/>
             <h1 className="philoHeadline">About Us</h1>
               
                    <div className="textBundlePhilo1">
 
                    <p className="objective_summary">Life After Life is pursuing a new paradigm of final resting place -- 
                 one that restores habitat to the earth rather than destroying it. 
                 By choosing Life After Life, our members directly heal the 
                 world we live in by building new native ecosystem memorial parks.</p>
				
                    </div>
                    <img  className="terrarium-one" alt="A cactus symbolizes Life After Life inside a sustainable cover, embodying eco-friendly and green practices." src='./images/Terrarium 3.webp' />

                  
                    <div className="textBundlePhilo2">
     
                    <p className="objective_summary">Inspired by the processes of nature itself, Life After Life is a new model 
                    of regenerative development for the future of people and the planet. In the forest, when a tree nears the end of its life, it releases its stored nutrients back into the ground, through an interconnected network of fungi and bacteria, feeding the plants around it. With their last breaths, they feed young trees in the network so that one day they can fill the space in the forest where the sick tree is lost.

In the age of the Anthropocene, we have come to play a critical role in nature. With our last breath, we can choose to rejuvenate the earth or continue to destroy it. 

	    </p>

                    </div>
                    <img className="terrarium-two"  alt="Plants thrives within a sustainable cover, representing eco-friendly placemaking and biodiversity restoration." src='./images/Terrarium 2.webp' />  
					 <div className="textBundlePhilo3">
						 <p className="objective_summary"> 
	     We want to make doing good easy. Reflected in our Motto, ‘One Last Good Deed’ - Life After Life is an opportunity for a loved-one’s loss to be a part of something positive for those they 
                    leave behind - An opportunity for a person’s last choice to contribute to healing the planet, leaving things better than they came. 
	   
	    We hope you can join us in making the world a little bit greener.</p>
					 </div>
					   <img  className="LastTerrarium"  alt="Enclosed in a sustainable cover, the vibrant plant symbolizes the revival of urban green spaces, reflecting eco-friendly placemaking and efforts for biodiversity restoration." src='./images/Terrarium 4.webp' />
          {/*   test */}
        </Container>
    )
}

export default Aboutus
