import React from 'react'
import './services.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSeedling } from '@fortawesome/free-solid-svg-icons';
import { faCloud } from '@fortawesome/free-solid-svg-icons';
import { faHandHoldingHeart } from '@fortawesome/free-solid-svg-icons';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faTree } from '@fortawesome/free-solid-svg-icons';
import { Container } from 'react-bootstrap'
import MetaData from "./metaData/metaData";

const metaData = {
	title: "Our Services | Life After Life - Modern Memorial Parks", 
	description: "Discover some of the sustainable deathcare services offered to members of Life After Life (LAL) Parks, the innovative leader in green burials and conservation cemeteries. Our Modern Memorial Park’s services include cloud memorials, natural burials, and nature immersed celebration-of-life events to honor loved one's life and legacy. Our plot customization interface allows you to create a personalized final resting place that reflects your unique vision and preferences. Our team is dedicated to reducing the impact of death on the environment and offers carbon-negative natural burial options such as forest burial, turning into a tree, and natural reduction. Your membership gives back to the community through pollution remediation and revitalization of defunct spaces. Discover why LAL Parks is changing the face of the cemetery and funeral landscape on our services page now.",
	author: "Life After Life", 
	type: "website", 
	image: "/images/Life After Life - Celebration of Life.webp", 
	url: "lifeafter.life/services"
}

function Services() {
    return(
        <Container fliud id="LifeAfterLifeServicePage">
            <MetaData {... metaData}/>
            <div className="all-services-container">              
            <h1 className="sMain-title">Member Benefits</h1>
            <div className="serve-container">
					 <div className="icon-wrapper"><FontAwesomeIcon icon={faTree}  className='trIcon symbol'/> </div>
							<span className="serviceText">
								<h3 className="subtitle">Modern Memorial Parks</h3>
								<p>
								Our Modern Memorial Parks are a sustainable end-of-life destination where the remediation of dilapidated 
								land into biodiverse, native-ecosystem, parks is powered by patrons who choose Life After Life as their final resting place.
								By becoming a Life After Life member, a plot is automatically reserved for you within one of our parks.
								</p>
							</span>
						</div>
						<div className="serve-container">
						<div className="icon-wrapper"><FontAwesomeIcon icon={faSeedling} className="scpIcon symbol" /></div>
							<span className="serviceText">
								<h3 className="subtitle">Nature Burials</h3>
								<p>
									Our Nature Burials do not use embalming fluid, ornate caskets, or
									burial vaults. Instead, remains are placed bare directly into
									the earth, within a biodegradable vessel, or in a simple
									compostable burial shroud, allowing the body to decompose
									naturally. This practice allows for natural reduction without
									polluting the ecosystem, returning the body's nutrients to the trees and 
									plants selected by the patron. All Life After Life memberships secure nature burial services.
								</p>
							</span>
						</div>

						<div className="serve-container">
						<div className="icon-wrapper"><FontAwesomeIcon icon={faCloud} className="cmIcon symbol" /></div>
							<span className="serviceText">
								<h3 className="subtitle">Cloud Memorials</h3>
								<p>
									Our Cloud Memorials immortalize personal histories. Visitors to the memorial park
									can experience an even deeper interaction by exploring a shared scrapbook of family
									and friend uploaded media. All Life After Life members recieve access to our Cloud Memorials.
								</p>
							</span>
						</div>

						<div className="serve-container">
						<div className="icon-wrapper"><FontAwesomeIcon
								icon={faHandHoldingHeart}
								className="pcIcon symbol"
							/></div>
							<span className="serviceText">
								<h3 className="subtitle">Plot Customization &amp; Selection</h3>
								<p>
									Our Plot Customization & Selection Interface allows customers to personalize
									their plot sections with horticulture that meet their meets their individual desires. All Life After Life members recieve access to our Plot Customization & Selection and other preplanning tools.
								</p>
							</span>
						</div>

						<div className="serve-container">
						<div className="icon-wrapper"><FontAwesomeIcon icon={faMapMarkerAlt} className="gslIcon symbol" /></div>
							<span className="serviceText">
								<h3 className="subtitle">GPS Spot Locating</h3>
								<p className="gps">
									{" "}
									Our GPS Spot Locating makes for easy wayfinding. Visitors can search
									for and return to the exact location of the burial of their loved ones using
									their mobile devices. GPS Spot Locating Services are provided for all Life After Life patrons.

								</p>
							</span>


						</div>

            <img src='/images/Life After Life - Park - One Last Good Deed.webp' alt="Resilient Paradise: Life After Life Park showcases sustainability, harmony with nature, and communal bonds, a haven for green enthusiasts." className='servicePic' />

        </div>
      </Container >
    
    )

}
export default Services;
