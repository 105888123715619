import React from 'react';
import JotformEmbed from 'react-jotform-embed';
import './contact.css';
import { Container } from 'react-bootstrap'
import MetaData from "./metaData/metaData";

const metaData = {
	title: "Contact | Life After Life - Get in Touch", 
	description: "Secure a sustainable final resting place plot while supporting the development of new parks where nature is needed most. Life After Life's modern memorial parks heal communities using conservation cemetery and green natural burial practices, remediating damaged ecosystems and restoring habitats with the endangered trees, plants, and animals that call this land home too. Join the fight against climate change and biodiversity collapse by choosing to give ‘One Last Good Deed’.", 
	author: "Life After Life", 
	type: "website", 
	image: "/images/Life After Life Logo Blended 6 Large size smaller file.webp", 
	url: "lifeafter.life/contactus"
}

function Contact() {
    return (
        <Container className="contactForm">
            <MetaData {... metaData}/>
            <div className="formBundle">
                <JotformEmbed src="https://form.jotform.com/212306430496148"/>
                <p id ="contact_information_short">
                    Have a specific inquiry? Email us at info@lifeafter.life or call 667-401-7556
                </p>
            </div>

        </Container>
    )
}

export default Contact
