import React from 'react';
import { Container } from 'react-bootstrap';
import './land.css';
import MetaData from "./metaData/metaData";

const metaData = {
	title: "Help Find Land | Life After Life - Benefits of Real Estate Donations", 
	description: "At Life After Life, we're dedicated to creating sustainable, green, regenerative communities that improve health and well-being. To help us achieve our mission, we're seeking underutilized or unused land that we can transform into vibrant, regenerative spaces. By donating real estate or easements, you'll be making a meaningful impact on the world, while also receiving valuable tax benefits. Our 'Land' page provides detailed information about the types of properties we're looking for and how you can donate. Whether you're a landowner looking to make a difference, or a community member or leader interested in supporting our mission through leads, we encourage you to explore this page and learn more about how you can contribute to our cause. Join us in creating a better, more sustainable future for all. Visit our 'Land' page now to get involved.",
	author: "Life After Life", 
	type: "website", 
	image: "/images/backtonature.webp", 
	url: "lifeafter.life/land"
}


function Land() {
    return(
        <Container>
        <MetaData {... metaData}/>
        <div className="land-background">
            <div className="landMain">
                <div className='textLeft'>
                    <h1 className="land_heading">Help Us Find Land</h1>
                    <p> 
                        If you know of underutilized land in your community
                        that you would like to see turned into parkspace instead,
                        we want to talk to you.
                    </p>
                    <p>
                        If you are a landowner looking to reduce your tax burden while
                        contributing to the betterment of the local community and
                        environment, please reach out. We can offer valuable
                        incentives through either our Land Donation or Conservation Easement Programs.
                    </p>
                    <a className='landButton' href="#land_donation">Land <br/>Donations</a>
                    <a className='landButton' href="#conservation_easements">Conservation <br/>Easements</a>
                </div>

                <img alt = "Nature's Enthusiasts: Women capturing the beauty of Life After Life Parks, fostering eco-awareness and celebrating the natural surroundings through photography." src = "./images/backtonature.webp"/>

            </div>
            <div className="landMain">
                <div>
                    <h2 className="land_heading">Why Us?</h2>
                    <p> 
                        Where the public sector lacks the finances or ability to build and maintain the greenspace that the community
                        dreams of, and the environment desperately needs - Life After Life is here to step in. As it stands today, 100 
                        million people in the United States, including 28 million kids, don’t have access to a park within a 10-minute walk
                        of home. This nature deprivation has tangible quality-of-life implications for those who inhabit the surrounding
                        community.
                    </p>
                </div>
            </div>

            <div className="landMain">
                <div>
                    <h2 className="land_heading" id= "land_donation">Charitable Real Estate Donation</h2>
                    <h4>What are the benefits of donating property to Life After Life?</h4>
                    <br/>
                    <p> 
                        By donating your real estate to Life After Life, not only do you give back to the earth and future generations, but
                        you also get to enjoy a variety of benefits over an attempted sale:
                    </p>
                    <ul>
                        <li>
                            <span className='listItem'>No Capital Gains Tax</span> – If you were selling your real estate, you would be required to also pay the
                            capital gains tax. Luckily, when it comes to charity real estate donation, there’s no such requirement. No
                            matter how much the property has appreciated in value, you won’t need to pay this tax.
                        </li>
                        <li>
                            <span className='listItem'>Charitable Income Tax Deduction</span> – Not only do you not have to pay the capital gains tax, but you will
                            even receive a charitable income tax deduction, based on the market value of your property.
                        </li>
                        <li>
                            <span className='listItem'>Transaction Cost Savings</span> - Skip the broker fees, significant transaction costs, and headaches
                            of trying to sell the property. (Not to mention the uncertainty of what might become of the land.)
                        </li>
                        <li>
                            <span className='listItem'>A Benevolent Legacy</span> - We will work with you to make sure you are publicly honored in the way that
                            s most desirable to you and your loved ones. You’ll be establishing an enduring legacy for yourself, or
                            whomever you choose - something both you and your future kids and grandkids will be proud of.
                        </li>
                        <li>
                            <span className='listItem'>A Deeply Meaningful Impact</span> - Most importantly, you’ll make a great philanthropic impact. Your 
                            charitable real estate donation will change many people’s lives for the better for generations to come. Your
                            gift will play a critical role in the fight to save the ecosystem in our time of crisis.
                        </li>
                    </ul>
                    <p>For more information please consult the <a className = "links" href = "https://www.irs.gov/charities-non-profits/contributors/tax-information-on-donated-property">Internal Revenue Service’s website</a> or talk to a tax professional.</p>
                </div>
            </div>

            <div className="landMain">
                <div>
                    <h2 className="land_heading" id= "conservation_easements">Conservation Easements</h2>
                    <h4>What are the benefits of donating a conservation easement with Life After Life?</h4>
                    <br/>
                    <p> 

                        Landowners who donate a conservation easement meeting federal requirements as a charitable gift may be eligible to take a 
                        federal income tax deduction for their gift and claim a state income tax credit. It is a
                        landowner’s responsibility, with the advice of their attorney or tax advisor, to ensure compliance with all federal
                        and state tax law requirements.
                    </p>
                    <p>
                        Conservation easement donors may deduct up to 50% of their adjusted gross income from their federal income
                        taxes the year the gift is made in addition to fifteen carry forward years (for a total of sixteen years) or until the
                        value of their donation is fully deducted, whichever comes first. Qualifying farmers and ranchers who donate a
                        conservation easement can deduct up to 100% of their adjusted gross income and are eligible for the same carry
                        forward period.
                    </p>
                    <br/>
                    <p>
                        For more information please consult the <a className= "links"href = "https://www.irs.gov/charities-non-profits/conservation-easements">Internal Revenue Service’s website</a> or talk to a tax professional.
                    </p>
                </div>
            </div>
            <img className = "parkPic" alt = "Eco-Haven at Life After Life Parks: A serene setting embracing greenery, biodiversity, and sustainable design, offering solace and harmony." src= "./images/Park.webp"/>
            <h4>Disclaimer</h4>
            <p>
                The information provided in this document is for general information purposes only. This information is not
                intended to be relied upon as legal advice.
            </p>
  
        </div>
    </Container>

    )

}
export default Land;
