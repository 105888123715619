import React from 'react';
import JotformEmbed from 'react-jotform-embed';
import './donations.css';
import { Container } from 'react-bootstrap';
import MetaData from "./metaData/metaData";

const metaData = {
	title: "Donate | Life After Life Foundation - Charity for Parks", 
	description: "Support our mission to create beautiful, sustainable parks that honor life and promote healing. At Life After Life (LAL) Parks, we're dedicated to providing individuals and communities with environmentally friendly, socially responsible deathcare options. By donating to our cause, you're helping us build innovative, modern memorial parks that incorporate elements of nature and promote conservation. Our 'Donations' page makes it easy to contribute to our cause and make a meaningful impact in the lives of those who are grieving. Your donation can help fund park development, tree planting, habitat restoration, and more. Join our community of supporters who are committed to creating a more sustainable, compassionate, and beautiful world. Visit our 'Donations' page now to make a difference today.",
	author: "Life After Life", 
	type: "website", 
	image: "/images/Life After Life Logo Blended 6 Large size smaller file.webp", 
	url: "lifeafter.life/donations"
}



function Donations() {
    return (
        <Container className="checkoutPage">
            <MetaData {... metaData}/>
            <div className= "checkout_formBundle">
                <JotformEmbed src="https://form.jotform.com/212365140470143"/>
            </div>
        </Container>
    )
}

export default Donations
