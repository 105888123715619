import React, { useState }  from "react"
import './banner.css';
function Banner() {
  const [isDisabled, setIsDisabled] = useState(false);

  return( 
    <div>
      {!isDisabled && (
        <div className="button-link-wrapper">
        <button
          className="disableBtn-banner"
          onClick={() => {
            setIsDisabled(true);
          }}
        >
          x
        </button>
        <a
          href="https://lifeafter.life/join"
          className="banner"
          target="_blank"
        >
          Start your membership journey today!
        </a>
      </div>
      )}

      
     

    </div>

  );
}

export default Banner
