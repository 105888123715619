import React, {useState } from 'react'
import "./build.css";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { Container } from 'react-bootstrap';
import { TfiArrowCircleLeft, TfiArrowCircleRight, TfiFullscreen } from "react-icons/tfi";
import MetaData from "./metaData/metaData";

const metaData = {
	title: "Build With Us | Life After Life - One Last Good Deed", 
	description: "Life After Life members believe in the power that transforming brownfields into sustainable and beautiful parks can have on communities. A memorial park is a wonderful way to honor the history of a loved one lost. Our 'Build' page explores our innovative approach to member supported and funded park developments on industrial reclaimed lands that otherwise would never be remediated. Our eco-friendly native habitat parks support biodiversity and are shaped by the desires of our patrons. The LAL team is experienced in regenerative development, including LEED certifications. We specialize in phytoremediation and restoration of degraded soils, creating green spaces that serve as catalysts for renewal and revitalization. Discover our process on our 'Build' page now.",
	author: "Life After Life", 
	type: "website", 
	image: "/images/renderings-with-logo-1.webp", 
	url: "lifeafter.life/build"
}

const SliderShow = () => {
    const images = [
      "/images/1_LifeAfterLife_renderings_with_logo.webp",
      "/images/2_LifeAfterLife_renderings_with_logo.webp",
      "/images/3_LifeAfterLife_renderings_with_logo.webp",
      "/images/4_LifeAfterLife_renderings_with_logo.webp",
      "/images/5_LifeAfterLife_renderings_with_logo.webp",
      "/images/LifeAfterLife_Deck_-_Boardwalk_Plan_view_with_logo_2.0.webp",
      "/images/7_LifeAfterLife_renderings_with_logo.webp",
      "/images/8_LifeAfterLife_renderings_with_logo.webp",
      "/images/9_LifeAfterLife_renderings_with_logo.webp",
      "/images/10_LifeAfterLife_renderings_with_logo.webp",
    ];
  
    const [isFullScreen, setIsFullScreen] = useState(false);

    React.useEffect(() => {
      const body = document.querySelector("body");
      if (isFullScreen) {
        body.style.overflow = "hidden";
        document.addEventListener("keydown", handleEscPress);
      } else {
        body.style.overflow = "";
        document.removeEventListener("keydown", handleEscPress);
      }
    }, [isFullScreen]);
  
    const handleToggleFullScreen = () => {
      setIsFullScreen(!isFullScreen);
    };

    const handleEscPress = (event) => {
      if (event.key === "Escape") {
        handleToggleFullScreen();
      }
    };

    const buttonStyle = {
        background: 'none',
        border: '0px',
    };
    
    const properties = {
        prevArrow: <button style={{ ...buttonStyle }}><TfiArrowCircleLeft style={{ color: '#f7faff', width: '35px', height:'35px', margin:'20px' }}/></button>,
        nextArrow: <button style={{ ...buttonStyle }}><TfiArrowCircleRight style={{ color: '#f7faff', width: '35px', height:'35px', margin:'20px' }}/></button>
    }
  
    return (
      <div className={`build-slider${isFullScreen ? ' fullscreen' : ''}`}>
        <Slide {...properties}>
          {images.map((image, index) => (
            <div key={index} className={`${isFullScreen ? ' slider-fullscreen' : ''}`}>
              <div className={`slider-render-img${isFullScreen ? ' img-fullscreen' : ''}`}
                style={{ backgroundImage: `url(${image})`}}
              />
            </div>
          ))}
        </Slide>
        <button onClick={handleToggleFullScreen} className={`${isFullScreen ? ' btn-fullscreen' : 'fullscreen-btn'}`} style={{ ...buttonStyle }}><TfiFullscreen style={{ color: '#f7faff', width: '30px', height:'30px', margin:'20px' }}/></button>
        {isFullScreen && <div className="overlay" onClick={handleToggleFullScreen} />}
      </div>
    );
};

const volunteer = '/images/Life_After_Life_-_Why_Build_-_Community_Volunteer_Conservation.webp';
const urbanpark = '/images/Life After Life - Urban Park - Recreation.webp';
const cemetery = '/images/Life_After_Life_-_Why_Build_-_Traditional_Cemetery_Wasteful.webp';

function Build() {
    return (
        <div id="build_body">
            <MetaData {... metaData}/>
            <SliderShow/>
						<Container>  
						<h1 className={`buildHeadline`}>Build With Us!</h1>
            <p className="buildSubheader">
                <br/>Life After Life is building modern memorial parks
                        in urban spaces. <br></br>If you’re like us and love nature and sustainability, then this IS
                        the last place you’ll want to be.</p>
                    <Container className="container">
                    <div className="textBundleBuild1">
                    <h2 className="subsection-title-build1">The World Needs Better</h2>
                    <p className="objective_summary">Hallmarks of Halloween and old folk lore, traditional cemeteries often get 
	    		abandoned after reaching capacity because the old civil war era cemetery business model is flawed. Worse yet, traditional cemetery
	    		practices render the space extremely burdensome for the generations who follow. What's left behind is a permanent detriment to our 
	    		cities, communities, and the planet. 
	    		<br></br>-<br></br> 
	    		At Life After Life, we believe abandonment-by-design is no way to honor the deceased, and that our final resting places should 
	    		improve, not burden the communities we leave behind - so we fixed it.</p>
				
                    </div>
                    <img src={cemetery} alt="Conventional Cemetery: A time-honored resting ground, but lacking sustainability practices." className="traditionalCemeteryPic" />
                    </Container>
                  
                    <Container className="container">
                    <div className="textBundleBuild2">
                    <div className="subsection-title-build2">Doing Good Is Now Easy</div>
                    <p className="objective_summary">At Life After Life, we support our members in their pursuit to live sustainably while offering
                            the ability, in the end, to perform 'One Last Good Deed' too. A life lived sustainably deserves to end sustainably too. Our patron's 
			contribution to the betterment of the Earth is tangible and verifiable, in a time when it's sometimes hard to tell.
			    <br></br>-<br></br> 
			    This is why we are building, and why we want to invite you to build with us. Think of it as your last chance to recycle the most important vessel you have.</p>

                    </div>
                    <img src={volunteer} alt="Volunteers nurturing nature, promoting sustainability, biodiversity, and urban green spaces with care and dedication at Life After Life" className="volunteerPic" />
                    </Container>
            <div className="promo-vid-wrapper build--promo-vid-wrapper">
                <iframe className="promo-vid"
                        src="https://www.youtube.com/embed/OZ_sh4NDlpo?autoplay=1&loop=1&playlist=OZ_sh4NDlpo&rel=0&modestbranding=1">
                </iframe>
            </div>
            <br></br>-<br></br> 
            <div className="textBundleBuild3">
                    <a className= "page-btn-2" href="/checkout" >Let's Start Building!</a>
            </div>
          
            <img src={urbanpark} alt="Life After Life's Urban Oasis: A sustainable recreational park developed with eco-friendly practices, fostering urban revitalization and community inclusivity." className="urbanparkPic" />
        </Container>
        </div>
    )
}

export default Build
