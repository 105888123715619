import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { authentication } from "../../../repository/firebase";
import ShowPassword from "../auth/ShowPassword";
import { withRouter } from 'react-router-dom';
import { getDatabase, connectDatabaseEmulator } from "firebase/database";
import { onHandleResendVerification } from './SignUp';

const SignIn = () => {

	const [accountEmail, setAccountEmail] = useState("");

	const [accountPassword, setAccountPassword] = useState("");

	const [accountPasswordError, setAccountPasswordError] = useState("");

	const [accountEmailError, setAccountEmailError] = useState("");

	const [showPassword, setShowPassword] = useState(false);

	const [verified, setVerified] = useState(true);

	const handleClearErrors = () => {
		setAccountEmailError("");
		setAccountPasswordError("");
	};
	const signInAnAccount = async () => {
		handleClearErrors();
		try {
			signInWithEmailAndPassword(authentication, accountEmail, accountPassword)
			.then((userCredential) => {
				// Signed in 
				console.log("account password:");
				const user = userCredential.user;
				if (user.emailVerified){
					console.log("correct password");
					window.location.href = "/portfolio";
				}
				else{
					setVerified(false);
				}
				// console.log(user);
				// console.log("inside");
				// window.location.href = "/portfolio";
			})
		} catch (error) {
			switch (error.code) {
				case "auth/invalid-email":
				case "auth/user-disabled":
				case "auth/user-not-found":
					setAccountEmailError("User Not Found");
					break;
				case "auth/wrong-password":
					setAccountPasswordError("Wrong Password");
					break;
				default:
					break;
			}
		}
	};


	return (
		<div>
			<div>
				{
					verified ? (
						<div>
						<label>Email</label>
						<input
							type="email"
							autoFocus
							required
							value={accountEmail}
							onChange={(e) => setAccountEmail(e.target.value)}
						/>
						</div>
					):
					(
						<div>
						<label>Email</label>
						<input
							type="email"
							autoFocus
							required
							value={accountEmail}
							onChange={(e) => setAccountEmail(e.target.value)}
						/>
						<div>Email address is not verified.</div>
						<button type="button" onClick={()=>{onHandleResendVerification()}}>verify</button>
						</div>
					)
				}
				
			</div>

			<p>{accountEmailError}</p>

			<label>Password</label>
			<input
				type={showPassword ? "text" : "password"}
				required
				value={accountPassword}
				onChange={(e) => setAccountPassword(e.target.value)}
			/>

			<ShowPassword
				setShowPassword={setShowPassword}
				showPassword={showPassword}
			/>

			<p>{accountPasswordError}</p>

			<div>
				<button onClick={signInAnAccount}> Sign in</button>
			</div>
		</div>
	);
};


export default withRouter(SignIn);
