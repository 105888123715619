// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyBwDPanaPzSL7QUs-81T-eehBw5R6ctyxk",
	authDomain: "life-after-life-1601e.firebaseapp.com",
	projectId: "life-after-life-1601e",
	storageBucket: "life-after-life-1601e.appspot.com",
	messagingSenderId: "270509967896",
	appId: "1:270509967896:web:bd0ffa814a8bd46969916d",
	measurementId: "G-E0ZQY54EBZ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const authentication = getAuth(app);
export const database = getDatabase();
