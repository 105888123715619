import { getAuth, onAuthStateChanged, updatePassword } from "firebase/auth";
import { database } from "../../../repository/firebase";
import React, { useState, useEffect } from "react";
import { ref, onValue, child, get, query, remove, set} from "firebase/database";
import './portfolio.css';
import './activity.css';
import { Line } from "react-chartjs-2";
import { CategoryScale, registerables, Chart, plugins} from "chart.js";
import { useSidebar } from './sidebarContext';


const badgeListMobile = ['T8', 'T7', 'T6', 'T5', 'T4', 'T3', 'T2','T1']
const badgeList = ['SUPPORTER', "CONTRIBUTER", "BUILDER", "SPONSOR", "CHAMPION", "FULL MEMBER", "PREMIUM MEMBER", "HERO"];
const badgeTier = [0, 100, 400, 1600, 3000, 5000, 10000,100000]

const plugin = {
    id: 'customCanvasBackgroundColor',
    beforeDraw: (chart, args, options) => {
      const {ctx} = chart;
      var chartArea = chart.chartArea;
      ctx.save();
      ctx.globalCompositeOperation = 'destination-over';
      ctx.fillStyle = options.color || 'black';
      ctx.fillRect(chartArea.left, chartArea.top, chartArea.right - chartArea.left, chartArea.bottom - chartArea.top);
      ctx.restore();
    }
  };

Chart.register(...registerables, plugin);

function LineChart({ chartData }) {
    return (
      <div className="chart-container">
        <Line
          data={chartData}
          plugins={[plugin]}
          options={
            {
            responsive: true,
            maintainAspectRatio: false,
            elements: {
                point:{
                    radius: 0
                }
            },
            scales: {
                x: {
                    grid: {
                        drawBorder: true,
                        color: '#808000',
                        drawTicks:false
                    },
                    ticks:{
                        beginAtZero: true,
                        color: '#808000',
                        font:{
                            family: 'Comfortaa', size: 12, weight: 'lighter',
                        }
                    }
                },
                y: {
                    grid: {
                        drawTicks: false,
                        drawBorder: true,
                        color: '#808000',
                    },
                    ticks: {
                        labelOffset: 13,
                        padding: 10,
                        beginAtZero: true,
                        color: '#808000',
                        font:{
                            family: 'Comfortaa', size: 12, weight: 'lighter',
                        },
                        callback: function (value) {
                            return badgeListMobile[value-1];
                        },
                    }
                }
            
            },
            plugins: {
                title: {
                    display: true,
                    text: 'Progress',
                    color: '#808000',
                    font: {family: 'Comfortaa', size: 23, weight: 'lighter'},
                },
                legend: {
                    display: false
                },
                customCanvasBackgroundColor: {
                    color: "#f7faff",
                }
            },
            // backgroundColor:'
            } 
        }
        />
      </div>
    );
  }

function calculateInterval(arr, x) {
    let intervalIndex = 0;
    while (intervalIndex < arr.length - 1 && x >= arr[intervalIndex + 1]) {
        intervalIndex++;
    }

    // Calculate the percentage
    const intervalStart = arr[intervalIndex];
    const intervalEnd = arr[intervalIndex + 1] || intervalStart; // If last interval, use the same value
    const intervalSize = intervalEnd - intervalStart;
    const percentage = (x - intervalStart) / intervalSize;
    console.log("----------")
    console.log(intervalIndex);
    console.log(percentage)
    // Return the result
    return intervalIndex + percentage;
}

const Activity = ({ userInfo, user, ...props })=>{
    const auth = getAuth();
    const profile = '/images/profile.webp'

    const { isSidebarCollapsed } = useSidebar();
    const dbRef = ref(database);
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [cumulativeDonation, setCumulativeDonation] = useState(0);
    const [subscription, setSubscription] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [tier, setTier] = useState(null);
    const [flag, setFlag] = useState(false);
    const [timeline, setTimeline] = useState(
        ['','...',]
    );
    const [donationTimeline, setDonationTimeline] = useState([]);
    const [chartData, setChartData] = useState({
        labels: timeline,
        datasets: [
        {
            data: [0, 1, 2, 3, 4, 5, 6, 7, 8],
            fill: false,
            borderColor: 'rgb(0, 0, 0, 0)',
        },
        {
            data: [0],
            fill: false,
            borderColor: '#808000',
        }
        ]
      });


    useEffect(()=>{
        setChartData(prevChartData => ({
            ...prevChartData,
            labels: timeline,
          }));
    }, [timeline])

    useEffect(()=>{
        setChartData(prevChartData => ({
            ...prevChartData,
            datasets: [
                ...prevChartData.datasets.slice(0, 1), // Keep the first dataset unchanged
                {
                    ...prevChartData.datasets[1], // Keep the existing properties of the second dataset
                    data: [(prevChartData.datasets[1].data.at(0)), donationTimeline[0]/2, ...donationTimeline], // Update the data array
                    pointRadius: [...Array(1+donationTimeline.length).fill(0), 2.5],
                    pointRotation: [...Array(1+donationTimeline.length).fill(0).fill(0), 0],//todo
                    pointStyle: [...Array(1+donationTimeline.length).fill(0).fill('circle'), 'circle'],
                    pointBackgroundColor: "#808000",
                },
                
              ],
        }))
    }, [donationTimeline])
    useEffect(() => {
        if (user && !flag) {
            if (!auth.currentUser.emailVerified){
                window.location.href = "/login";
            }
            const uid = user.uid;
            var tmpStartDate = startDate;
            var tmpCumulativeDonation = cumulativeDonation;
            var tmpTimeline = [];
            var tmpDonationTimeline = [];
            get(child(dbRef, `/accounts/${uid}/donations`)).then((snapshot) => {
            if (snapshot.exists()) {
                // console.log(snapshot.val());
                snapshot.forEach((childSnapShot) => {
                    if (childSnapShot.key === 'subscriptions' && parseInt(childSnapShot.val()) > 0){
                        setSubscription(childSnapShot.val())
                    }
                    if (childSnapShot.key != 'subscriptions') {
                        tmpCumulativeDonation = tmpCumulativeDonation + parseInt(childSnapShot.val().donationAmount);
                        const date = new Date(childSnapShot.val().donationDate);
                        if (!(tmpStartDate && tmpStartDate.getTime() < date)){  
                            tmpStartDate = date;
                        }
                        setStartDate(tmpStartDate);
                        tmpTimeline.push(date.toLocaleDateString());
                        const res = calculateInterval(badgeTier, tmpCumulativeDonation)
                        tmpDonationTimeline.push(res);
                    }
                })  
                setCumulativeDonation(tmpCumulativeDonation);
                var idx = -1;
                for (let i = 0; i < badgeTier.length; i++){
                    if (tmpCumulativeDonation >= badgeTier[i]){
                        idx = i;
                    }
                }
                if (idx != -1){
                    setTier(badgeList[idx])
                }
                setDonationTimeline(prevDonationTimeline=>{
                    const newDonationTimeline = [...prevDonationTimeline, ...tmpDonationTimeline];
                    return newDonationTimeline;
                })
                setTimeline(prevTimeline => {
                    const newTimeline = [...prevTimeline, ...tmpTimeline, 'to be continued...'];
                    return newTimeline;
                  });
                setFlag(true);
            } else {
                console.log("No data available");
            }
            }).catch((error) => {
            console.error(error);
            });
            // console.log("signed in")
        } else {
            // console.log("not signed it")
            // window.location.href = "/login"
            // setTimeout(returnToSignIn, 1000);
        }
      }, [user]);

    useEffect(() => {
        const handleResize = () => {
          setIsSmallScreen(window.innerWidth < 600);
        };
    
        // Initial check on mount
        handleResize();
    
        // Add event listener for window resize
        window.addEventListener('resize', handleResize);
    
        // Cleanup: Remove the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);


    // return (isSmallScreen && !isSidebarCollapsed) ? (
    return (false) ? (
        //
        null
    ):(
        <div className={!isSidebarCollapsed?'portfolio-wrapper-mobile-overlap':'portfolio-wrapper'}>
        {true ? (
            <div className="activity-wrapper">
                <div className="ellipse-portfolio-wrapper">
                    <div className="ellipse-portfolio">
                        <img src={profile} alt="profile image" className="ellipse-portfolio-img"/>
                    </div>
                    <div className="name-portfolio">{userInfo.firstName}</div>
                </div>
                <div className="activity-info-wrapper">

                    <div className='activity-info-heading'>Activity</div>
                    <div className='activity-bundle'>
                        <div className='activity-info-title'>Cumulative Donation</div>
                        <div className='activity-info-content'>${cumulativeDonation.toFixed(2)}</div>
                    </div>
                    <div className='activity-bundle'>
                        <div className='activity-info-title'>Subscription Plan</div>
                        <div className='activity-info-content'>{subscription?(subscription):("$0 / month")}</div>
                    </div>
                    <div className='activity-bundle'>
                        <div className='activity-info-title'>Member Since</div>
                        <div className='activity-info-content'>{startDate?(startDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })):("N/A")}</div>
                    </div>
                    <div className='activity-bundle'>
                        <div className='activity-info-title'>Current Tier</div>
                        <div className='activity-info-content'>{tier}</div>
                    </div>

                </div>
                <div className='activity-chart'><LineChart chartData={chartData}/></div>
                
            </div>
            ) : (
                // User is not signed in, display appropriate content
                // window.location.href = "/login"
                console.log("not logged in")
            )}
        </div>
    );
}

export default Activity
