import {React} from 'react'
import Collapsible from 'react-collapsible';
import JotformEmbed from 'react-jotform-embed';
import './volunteer.css';
import { Container } from "react-bootstrap";
import { BiPlus, BiMinus } from "react-icons/bi";
import MetaData from "./metaData/metaData";

const metaData = {
	title: "Volunteer | Life After Life - Building a Better Tomorrow, Together", 
	description: "At Life After Life, we're on a mission to create regenerative communities through sustainable deathcare. To achieve our goals, we need volunteers who share our vision and are committed to making a difference. Our 'Volunteer' page provides information about the different ways you can get involved, whether nearby or remotely. Our volunteer community is helping us spread the word about our mission. Whether you're a student looking for hands-on experience or a community member looking to make a difference, we welcome your support. Join our team of passionate volunteers and help us create a better, more sustainable future for all. Visit our 'Volunteer' page now to learn more and apply to get involved.",
	author: "Life After Life", 
	type: "website", 
	image: "/images/Protect planet.webp", 
	url: "lifeafter.life/volunteer"
}


function VolunteerCollapse({question, answer}){
    const text = 
        <p className="subsection-title1-collapsible">
            {question}
        </p>;
    const plus = <BiPlus className= "--plus-sign-volunteer" size = "35px"/>;
    const minus = <BiMinus className= "--plus-sign-volunteer --plus-sign__opened" size = "35px"/>;
    
    return <Collapsible 
            className = "volunteer-collapsible" 
            openedClassName = "volunteer-collapsible" 
            trigger = {[plus, text]}
            triggerWhenOpen = {[minus, text]}
            >
            <div className="volunteer-faq-answer">{answer}</div>
        </Collapsible>;
}

function Volunteer() {
    return (
        <Container id = "LifeAfterLifeVolunteerPage">
            <MetaData {... metaData}/>
                   
             <h1 className="volunteerHeadline">Volunteer at Life After Life</h1>
               
               
                    <div className="textBundleVolunteer1">
                    
                    <p className="objective_summary">
                            <em>Do you want to be on the forefront of environmental change?</em>
                            <br>
                            </br> 
	     <br>
                            </br>
	    		If you’re like us and love nature and sustainability, consider joining our
                            team at Life After Life! We offer a variety of opportunities to directly contribute to our mission
                            of creating a greener future.</p>
                    </div>
                     <img class="leaf-img" src="./images/leaves.webp" alt="different types of leaves graphic, symbolic of our green and sustainable ideology at Life After Life" />

                  
                    <div className="textBundleVolunteer2">
                    <h2 className="subsection-title1">Please review the Frequently Asked Questions section at
                        the bottom of this page for more information before
                        signing up.</h2>
                    </div>
                     <img class="protectImg" src="./images/Protect planet.webp" alt="Green Champions at Life After Life: Two people united to save the planet, embodying sustainability and eco-conscious principles." />

            <div className="volunteerFormBundle">
               <JotformEmbed src="https://form.jotform.com/221083493806053" />
            </div>
			<div className="textBundleVolunteer3">
            <div className="volunteer-faq-bundle">
            <p className="volunteer-faq-title">Frequently Asked Questions</p>
            <VolunteerCollapse question="What are you looking for in a volunteer?" answer="People like you! At Life After Life, we believe
                    everyone is able to contribute in their own unique
                    way for a more sustainable way of life."/>
            <VolunteerCollapse question="What type of volunteer opportunites are available?" answer="There are a variety of tasks that you can partake in as a volunteer. Opportunities like
                    community outreach, marketing, assisting in workshops, gardening, and other options are available for
                    volunteering. To stay updated on opening positions or to just see what's new at Life After Life, sign
                    up below!"/>
            </div>
            <p className="objective_summaryFinal">
            <em>Unable to volunteer at this time?</em>
            <br></br>
            Consider donating to Life After Life, where we
            strive to leave the earth better than when we
            found it.
            </p>
            <a href="/join" className="volunteerCheckout">Become a Lifer</a>
        </div>
        </Container>
    )
}

export default Volunteer
