import React, { useEffect, useState } from "react";
import { createUserWithEmailAndPassword, sendEmailVerification, getAuth} from "firebase/auth";
import { authentication, database } from "../../../repository/firebase";
import { ref, set } from "firebase/database";
import { statesList } from "../../../repository/account/statesList";
import { handlePhoneNumberFormat } from "../../../utils/phoneNumberFormatter";
import ShowPassword from "../auth/ShowPassword";

export const onHandleResendVerification = () => {
	const auth = getAuth();
	const user = auth.currentUser;
	
	alert("a verification email has been sent to your email");
	sendEmailVerification(user);
}

const SignUp = () => {

	const [accountPasswordError, setAccountPasswordError] = useState("");

	const [accountEmailError, setAccountEmailError] = useState("");

	const [showPassword, setShowPassword] = useState(false);

	const [passwordsMatch, setPasswordsMatch] = useState(false);

	const [activateEmail, setActivateEmail] = useState(false);

	const handleClearErrors = () => {
		setAccountEmailError("");
		setAccountPasswordError("");
	};
	

	const [signUpObject, setSignUpObject] = useState({
		email: "",
		password: "",
		confirmPassword: "",
	});

	const [user, setUser] = useState({
		firstName: "",
		lastName: "",
		gender: "",
		phone: "",
		address: "",
		city: "",
		state: "",
		donationAmount: "",
		emailAddress: "",
		donationId: {},
		transactionId: {},
		eventId:{},
	});
	const signUpAnAccount = async (e) => {

		e.preventDefault();

		handleClearErrors();
		
		try {
			if (passwordsMatch) {
				const account = await createUserWithEmailAndPassword(
					authentication,
					signUpObject.email,
					signUpObject.password
				);
				console.log(account);

				if (account && account.user) {
					console.log(user);
					const updatedUser = { ...user, emailAddress: signUpObject.email };
					await set(ref(database, `/accounts/${account.user.uid}`), updatedUser);
					// await set(ref(database, `/emailAccountMap/${signUpObject.email}`), account.user.uid);
				}

				alert("a verification email has been sent to your email");
				await sendEmailVerification(account.user);
			
				if (account.user.emailVerified){
					console.log("email verified");
					//create a new field in user data that marks if the account is verified or not
					window.location.href = "/login";
				}
				else{
					setActivateEmail(true);
				}
			} else {
				alert("passwords don't match ");
			}
		} catch (error) {
			switch (error.code) {
				case "auth/email-already-in-use":
					setAccountEmailError("Email is already taken");
					break;
				case "auth/invalid-email":
					setAccountEmailError("Invalid Email");
					break;
				case "auth/weak-password":
					setAccountPasswordError(
						"Password is Weak. It has to be more than 5 characters."
					);
					break;
				default:
					console.log("here");
					break;
			}
		}
	};

	const onHandleChange = (e) => {
		setSignUpObject({ ...signUpObject, [e.target.name]: e.target.value });
	};

	const onHandleChangeForUser = (e) => {
		if (e.target.name === "phone") {
			setUser({
				...user,
				[e.target.name]: handlePhoneNumberFormat(e.target.value),
			});
		} else {
			setUser({ ...user, [e.target.name]: e.target.value });
		}
	};

	useEffect(() => {
		if (signUpObject.password === signUpObject.confirmPassword) {
			setPasswordsMatch(true);
		} else {
			setPasswordsMatch(false);
		}
	}, [signUpObject.password, signUpObject.confirmPassword]);

	return (
		<div>
		{
		activateEmail ? (
			<div>
				<span>{signUpObject.email}</span>
				<button type="button" onClick={() => onHandleResendVerification()}>resend</button>
				<div>
					<button type="button" onClick={() => {setActivateEmail(false); window.location.href = "/login";}}>log in</button>
				</div>
			</div>
		):
		(
			<form className="form-SignUp" onSubmit={signUpAnAccount}>
				<div>
					<div>
						<label className="label-SignUp">Gender*</label>
						<select className="selection-SignUp"
							name="gender"
							value={user ? user.gender : ""}
							onChange={(e) => onHandleChangeForUser(e)}
						>
							<option value="" disabled>
								Select
							</option>
							<option value="Man">Man</option>
							<option value="Woman">Woman</option>
							<option value="Non-Binary">Non-Binary</option>
						</select>
					</div>

					<label className="label-SignUp">First Name*</label>
					<input className="input-SignUp"
						type="text"
						name="firstName"
						value={user ? user.firstName : ""}
						onChange={(e) => onHandleChangeForUser(e)}
						autoFocus
						required
					/>
				</div>

				<div>
					<label className="label-SignUp">Last Name*</label>
					<input className="input-SignUp"
						type="text"
						name="lastName"
						value={user ? user.lastName : ""}
						onChange={(e) => onHandleChangeForUser(e)}
						autoFocus
						required
					/>
				</div>

				<div>
					<label className="label-SignUp">Enter your phone number:*</label>
					<input className="input-SignUp"
						type="text"
						name="phone"
						value={user ? user.phone : ""}
						onChange={(e) => onHandleChangeForUser(e)}
						required
					/>
				</div>

				<div>
					<label className="label-SignUp">Address*</label>
					<input className="input-SignUp"
						type="text"
						name="address"
						value={user ? user.address : ""}
						onChange={(e) => onHandleChangeForUser(e)}
						required
					/>
				</div>

				<div>
					<label className="label-SignUp">City*</label>
					<input className="input-SignUp"
						type="text"
						name="city"
						value={user ? user.city : ""}
						onChange={(e) => onHandleChangeForUser(e)}
						required
					/>
				</div>

				<div>
					<label className="label-SignUp">State*</label>
					<select className="selection-SignUp"
						name="state"
						value={user ? user.state : ""}
						onChange={(e) => onHandleChangeForUser(e)}
					>
						<option value="" disabled>
							Select
						</option>
						{statesList.map((state, idx) => (
							<option key={idx} value={state}>
								{state}
							</option>
						))}
					</select>
				</div>

				<div>
					<label className="label-SignUp">Email*</label>
					<input className="input-SignUp"
						type="email"
						name="email"
						value={signUpObject.email}
						onChange={(e) => onHandleChange(e)}
						autoFocus
						required
					/>
				</div>

				<p>{accountEmailError}</p>

				<div>
					<label className="label-SignUp">Password*</label>
					<input className="input-SignUp"
						type={showPassword ? "text" : "password"}
						required
						name="password"
						value={signUpObject.password}
						onChange={(e) => onHandleChange(e)}
					/>
				</div>

				<div>
					<label className="label-SignUp">Confirm Password*</label>
					<input className="input-SignUp"
						type={showPassword ? "text" : "password"}
						required
						name="confirmPassword"
						value={signUpObject.confirmPassword}
						onChange={(e) => onHandleChange(e)}
					/>
				</div>

				<ShowPassword
					setShowPassword={setShowPassword}
					showPassword={showPassword}
				/>

				<div style={{ color: passwordsMatch ? "green" : "red" }}>
					{signUpObject.confirmPassword.length > 0 && (
						<p>{passwordsMatch ? "Passwords Match" : "Passwords Don't Match"}</p>
					)}
				</div>

				<p>{accountPasswordError}</p>

				<div>
					<button type="submit"> Sign Up</button>
				</div>
			</form>
		)
		}
		</div>
	);
};

export default SignUp;