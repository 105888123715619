import React from 'react'
import "./notfound.css";
import { Container } from 'react-bootstrap'

function NotFound() {
  return (
    <Container className="urlBackground">
      <head>
        <title>
          Not Found
        </title>
      </head>
      <body>
      <div className="urlBundle">
        <h className="urlTitle">Sorry, something is wrong!</h>
        <p className="urlText"> You likely entered entered the wrong url or were trying to reach a page we haven't finished yet.
          Here is our website: <a href="https://www.lifeafter.life" className="lal-url" style={{ color: "#808000" }}>Life After Life</a></p>
      </div>
      </body>
    </Container>

  )
}

export default NotFound
