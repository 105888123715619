import React from 'react';
import { useState } from 'react'; 
import './footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faTumblr } from '@fortawesome/free-brands-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faPinterest } from '@fortawesome/free-brands-svg-icons';
import { faTiktok } from '@fortawesome/free-brands-svg-icons';
import { faSpotify } from '@fortawesome/free-brands-svg-icons';
import { withRouter } from 'react-router-dom';

const cart = '/images/whiteCart.webp';
const redwheelbarrow = '/images/wheelbarrow_final_red_.webp'



function Footer(props) {

    const [selected,setSelected] = useState(null)

    const toggle = (i) => {

        if (selected === i) {
            return setSelected(null); 
        }
        setSelected(i)

    }

    const [over,setOver] = useState(false); 
    
    return (
        <div className="massbox">
            <div className="footerPage">
                <div className="footerContainer">
                    <div className="footerLine">
                        <div class="footerCol">
                            <h4>Talk to Us!</h4>
                            <ul>
                                <li>
                                    <a className="interact" href="mailto:info@lifeafter.life">info@lifeafter.life</a><br />
                                    <a className="interact" href="tel:6674017556">(667) 401-7556</a><br />
                                    <div onMouseOver={() => setOver(true)} onMouseOut ={() => setOver(false)}><a className="interact" href="/join"> Join <span>
                                        <img src={over ? redwheelbarrow : cart} alt="wheelbarrow"className="cart"/>
                                    </span></a></div><br />
                                    <a className="interact" href="/blog">Blog</a><br />
                                    <a className="interact" href="/contactus">Contact Us</a><br />
                                    <a className="interact" href="/careers">Careers</a><br />
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="footerLine">
                        <div className="footerCol">
                            <h4>Who Are We</h4>
                            <ul>
                                <li>
                                    <a className="interact" href="/whoarewe">Our Team</a><br />
                                    <a className="interact" href="/services">Our Services </a><br />
                                    <a className="interact" href="/about-us">About Us</a><br />
                                    <a className="interact" href="/build">Why Build?</a><br />
                                    <a className="interact" href="/heal">How We Heal</a><br />
                                    <a className="interact" href="/whatswrong">What's Wrong?</a><br />
                                    <a className="interact" href="/articles">Press </a><br />
                                </li>
                            </ul>
                        </div>
                    </div>


                    <div className="footerLine">
                        <div className="footerCol">
                            <h4>Benefactors</h4>
                            <ul>
                                <li>
                                    <a className="interact" href="/donations">Donate</a><br />
                                    <a className="interact" href="/invest">Invest in Parks</a><br />
                                    <a className="interact" href="/land">Help Find Land</a><br />
                                    <a className="interact" href="/volunteer">Volunteer</a><br />
                                </li>
                            </ul>
                        </div>
                    </div>


                    <div className="footerLine">
                        <div className="footerCol">
                            <h4>Patrons</h4>
                            <ul>
                                <li>
                                    <a className="interact" href="/join">Become a Lifer</a><br />
                                    <a className="interact" href="/store">Gift Shop</a><br />
                                    <a className="interact" href="/learn">Help Us Learn</a><br />
                                    <a className="interact" href="/stayinformed">Stay Informed</a><br />
                                </li>
                            </ul>
                        </div>
                    </div>


                    <div className="footerLine">
                        <div className="footerCol">
                            <h4>Events</h4>
                            <ul>
                                <li>
                                    <a className="interact" href="/community">Community</a><br />
                                    <a className="interact" href="/education">Education</a><br />
                                </li>
                            </ul>
                        </div>
                    </div>


                </div>

                <br />
                <div className="socialMediaBundle">
                        <a href="https://www.facebook.com/LifeAfterLifeParks" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFacebookF} className='facebook logo' /></a>
                        <a href="https://twitter.com/LifeAfterLifeUS" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faTwitter} className='twitter logo' /></a>
                        <a href="https://www.instagram.com/lifeafterlifeparks/" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faInstagram} className='instagram logo' /></a>
                        <a href="https://www.linkedin.com/company/life-after-life" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faLinkedin} className='logo' /></a>
                        <a href="https://www.tumblr.com/blog/lifeafterlifeparks" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faTumblr} className='tumblr logo' /></a>
                        <a href="https://www.youtube.com/@LifeAfterLifeParks" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faYoutube} className='youtube logo' /></a>
                        <a href="https://open.spotify.com/user/315kws65snviyl5goecixbpixi3e" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faSpotify} className='spotify logo' /></a>
                        <a href="https://www.pinterest.com/lifeafterlifeparks/" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faPinterest} className='pintrest logo' /></a>
                        <a href="https://www.tiktok.com/@lifeafterlifeparks" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faTiktok} className='tikitok logo' /></a>

                </div>
                <br />
                <div className='infoTrademark'>
                <span className="trademark">501(c)(3) Nonprofit │ Copyright © 2024 by Life After Life - All Rights Reserved</span>
                <span className='privacy'>
                    <a href='https://lifeafter.life/privacy'className='pclink'>Privacy Policy</a>
                    <p>&nbsp;|&nbsp;</p>
                    <a href='https://lifeafter.life/privacy' className='pclink'>Cookies Policy</a>
                </span>
                </div>
                <br />


            </div>
           

                       
     
            <div className="macro-wrapper">
                <div className="wrapper">
                    <div className="accordion">
                        {data.map((item,i) => (
                            <div className="item">
                                <div className="title-wrapper" onClick={()=> toggle(i)}>   
                                    <h2 className="title">
                                        {item.question}
                                    </h2>   
                                    <span className="plus-minus">{selected === i ? '-' : '+'}</span>                  
                                </div>
                                
                                <div className={selected === i ? 'content show' : 'content'}>
                                    {item.answer}
                                </div>
                            </div>
                        ))}
                        
                    </div>
                
                </div>
                <hr className="hr"></hr>
                <br />
                <div className="socialMediaBundle">
                        <a href="https://www.facebook.com/LifeAfterLifeParks" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFacebookF} className='facebook logo' /></a>
                        <a href="https://twitter.com/LifeAfterLifeUS" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faTwitter} className='twitter logo' /></a>
                        <a href="https://www.instagram.com/lifeafterlifeparks/" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faInstagram} className='instagram logo' /></a>
                        <a href="https://www.linkedin.com/company/life-after-life" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faLinkedin} className='logo' /></a>
                        <a href="https://www.tumblr.com/blog/lifeafterlifeparks" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faTumblr} className='tumblr logo' /></a>
                        <a href="https://www.youtube.com/@LifeAfterLifeParks" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faYoutube} className='youtube logo' /></a>
                        <a href="https://open.spotify.com/user/315kws65snviyl5goecixbpixi3e" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faSpotify} className='spotify logo' /></a>
                        <a href="https://www.pinterest.com/lifeafterlifeparks/" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faPinterest} className='pintrest logo' /></a>
                        <a href="https://www.tiktok.com/@lifeafterlifeparks" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faTiktok} className='tikitok logo' /></a>

                </div>
                <br />
                <div className='infoTrademark'>
                <span className="trademark">501(c)(3) Nonprofit │ Copyright © 2024 by Life After Life - All Rights Reserved</span>
                <span className='privacy'>
                    <a href='https://lifeafter.life/privacy'className='pclink'>Privacy Policy</a>
                    <p>&nbsp;|&nbsp;</p>
                    <a href='https://lifeafter.life/privacy' className='pclink'>Cookies Policy</a>
                </span>
                </div>
        

                
            </div>
            
        </div>
        
    )
}


const data = [
    {
        question: 'Talk to Us!',
        answer: 
        <div>
            <br />
            <a className="contactEmail" href="mailto:info@lifeafter.life">info@lifeafter.life</a> 
            <p className="contact info">(667) 401-7556</p>
            <button className="reservationBtn"><a href="/join" style={{textDecoration: 'none', color: '#f7faff'}}> Join <span><img src={cart} alt="green wheelbarrow"className="cart"/></span></a></button>
            <a href="/contactus" style={{textDecoration: 'none'}}><p className="contact info pointer">Contact Us</p></a>
            <a href="/blog" style={{textDecoration: 'none'}}><p className="contact info pointer">Blog</p></a>
            <a href="/careers" style={{textDecoration: 'none'}}><p className="contact info pointer">Careers</p></a>
        </div>
    },
    {
        question: 'Who Are We',
        answer:
        <div>
            <a href="/whoarewe" style={{textDecoration: 'none'}}><p className="contact info pointer">Our Team</p></a>
            <a href="/services" style={{textDecoration: 'none'}}><p className="contact info pointer">Our Services</p></a>
            <a href="/about-us" style={{textDecoration: 'none'}}><p className="contact info pointer">About Us</p></a>
            <a href="/build" style={{textDecoration: 'none'}}><p className="contact info pointer">Why Build?</p></a>
            <a href="/heal" style={{textDecoration: 'none'}}><p className="contact info pointer">How We Heal</p></a>
            <a href="/whatswrong" style={{textDecoration: 'none'}}><p className="contact info pointer">What's Wrong?</p></a>
            <a href="/articles" style={{textDecoration: 'none'}}><p className="contact info pointer">Press</p></a>
        </div>
    },
    {
        question: 'Benefactors',
        answer:
        <div>
            <a href="/donations" style={{textDecoration: 'none'}}><p className="contact info pointer">Donate</p></a>
            <a href="/invest" style={{textDecoration: 'none'}}><p className="contact info pointer">Invest in Parks</p></a>
            <a href="/land" style={{textDecoration: 'none'}}><p className="contact info pointer">Help Find Land</p></a>
            <a href="/volunteer" style={{textDecoration: 'none'}}><p className="contact info pointer">Volunteer</p></a>
        </div>
    },
    {
        question: 'Patrons',
        answer:
        <div>
            <a href="/join" style={{textDecoration: 'none'}}><p className="contact info pointer">Become a Lifer</p></a>
            <a href="/store" style={{textDecoration: 'none'}}><p className="contact info pointer">Gift Shop</p></a>
            <a href="/learn" style={{textDecoration: 'none'}}><p className="contact info pointer">Help Us Learn</p></a>
            <a href="/stayinformed" style={{textDecoration: 'none'}}><p className="contact info pointer">Stay Informed</p></a>
        </div>
    },
    {
        question: 'Events',
        answer:
        <div>
            <a href="/community" style={{textDecoration: 'none'}}><p className="contact info pointer">Community</p></a>
            <a href="/education" style={{textDecoration: 'none'}}><p className="contact info pointer">Education</p></a>
        </div>
    }


]

export default withRouter(Footer)
