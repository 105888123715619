import React from 'react'
import "./learn.css"
import JotformEmbed from 'react-jotform-embed'
import { Container } from 'react-bootstrap'
import MetaData from "./metaData/metaData";

const metaData = {
	title: "Help Us Learn | Life After Life - Researching End-of-Life Decisions", 
	description: "Life After Life is always looking to learn about the diverse geographic, cultural, and religious perspectives involved when making end-of-life decisions. We believe that funeral services should reflect the values and needs of individuals and families today, not from centuries ago. We're committed to learning more about what matters most to funeral customers like you. That's why we're seeking your help - Please share your voice by filing out our research survey on funeral customer decision making and values. By sharing your insights and experiences, you can help us create more personalized, meaningful, and inclusive services and offerings. Together, we can make the funeral industry more responsive to the needs of all.", 
	author: "Life After Life", 
	type: "website", 
	image: "/images/family.webp", 
	url: "lifeafter.life/learn"
}
const berryBranch = '/images/berry_branch.webp'
const yellowBranch = '/images/forsythia_branch_diag.webp'

function Learn() {
    return (
        <Container id = "LifeAfterLifeLearnPage">
            <MetaData {... metaData}/>
             <h1 className="learnHeadline">Help Us Learn</h1>
               
               
                    <div className="textBundleLearn1">
                    <h2 className="subsection-title">Our Objective</h2>
                    <p className="objective_summary">Our objective is to create a hospitality-focused environment that
                        plays a valuable social role in the community. A place of comfort and connectedness with others.
                        Experience-enhancing technology replaces the traditional gravestones. While the continued curation
                        of experiential hospitality programming and installations continue to provide reasons beyond grief
                        for the consumer to return. In providing these ancillary benefits, we hope to become top-of-mind
                        for future internment decisions. </p>
                    </div>
                    <img src={berryBranch} alt="Memorial Park's Berry Branch: Nature's tribute, thriving at Life After Life, symbolizing remembrance, growth, and eco-friendly practices" className="berryBranchPic" />

                  
                    <div className="textBundleLearn2">
                    <h2 className="subsection-title">Inclusivity</h2>
                    <p className="objective_summary">Equally important is designing with spiritual embodiments of funeral
                        practices in mind. Natural burials and the offering of the body as charity to the earth are common
                        practices amongst several American minority faiths, but each has special considerations. Environmentalism
                        and the do-good drive extend to the non-religious commonly. A motif that other conservation cemeteries have
                        found as a successful bridge to the Christian majority adopting the new tradition is the interfaith idea of
                        unity with earth referred to as “ashes to ashes, dust to dust”.</p>

                    </div>
                    <img src={yellowBranch} alt="Life After Life's Floral Tribute: A yellow branch adorns the memorial park, celebrating life's beauty and sustainable remembrance." className="yellowBranchPic" />
            <div className="learnFormBundle">
                <JotformEmbed src="https://form.jotform.com/221595855301154" />
            </div>
        </Container>
    )
}

export default Learn
