import React from 'react';
import { Container } from 'react-bootstrap';
import './education.css'


function Education() {
    return(
        <Container>
        <head>
            <title>
                Education
            </title>
        </head>
        <body>
        <div className="education-background">
            <div className="education_Main">
                <h1 className="education_heading">Coming Soon: Education</h1>
                <p className="education_context"> Life After Life is building modern memorial parks in urban spaces. If you’re like us and love nature and sustainability, then this IS the last place you’ll want to be.</p>
            </div>
  
        </div>
        </body>

    </Container>

    )

}
export default Education;