import React from 'react';
import { Helmet } from 'react-helmet-async';



export default function MetaData({title, description, author, type, image, url}) {
return (
  <Helmet>
    { /* Standard metadata tags */ }
    <title>{title}</title>
    <meta name='description' content={description} />
    { /* End standard metadata tags */ }
    { /* Facebook tags */ }
    <meta property="og:title" content={title} />
    <meta property="og:type" content={type} />
    <meta property="og:site_name" content="Life After Life"/>
    <meta property="og:image" content={image}/>
    <meta property="og:url" content={url}/>
    <meta property="og:description" content={description}/>
    { /* End Facebook tags */ }
    { /* Twitter tags */ }
    <meta name="twitter:creator" content={author} />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    { /* End Twitter tags */ }
  </Helmet>
)
}