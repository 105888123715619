import React, {useState} from 'react'
import './heal.css';
import { Container } from 'react-bootstrap'
import MetaData from "./metaData/metaData";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { TfiArrowCircleLeft, TfiArrowCircleRight, TfiFullscreen } from "react-icons/tfi";

const metaData = {
	title: "How We Heal | Life After Life - Building a Better Tomorrow, Together", 
	description: "At Life After Life, our members believe that nature has the power to heal, and we're committed to creating spaces that facilitate the healing process. Our 'Heal' page explores the therapeutic and restorative benefits of nature and our dedication to providing opportunities for individuals and communities to reconnect with the environment. Our parks are designed to provide habitat for native wildlife, foster biodiversity, and create a sense of peacefulness and tranquility in otherwise nature-starved areas. Our green burial and modern memorialization services honor the environment while facilitating the natural symbiotic processes of life. Our team is dedicated to providing compassionate and personalized support for those who are experiencing loss or are seeking a deeper connection with nature. Discover how LAL’s Parks can help you find comfort, solace, and healing in nature. Visit our 'Heal' page now.",
	author: "Life After Life", 
	type: "website", 
	image: "/images/renderings-with-logo-2.webp", 
	url: "lifeafter.life/heal"
}

const SliderShowHeal = () => {
    const images = [
        {
          url: "/images/Biodiversity_Crisis_Animals.webp",
          alt_text: "Nature's Dire Shift: 5 Drivers Impacting Wildlife, Pushing Animals to Brink of Extinction - Slide reveals urgent need for conservation."
        },
        {
          url: "/images/Biodiversity%20Crisis-Fisheries.webp",
          alt_text: "Fisheries' Impact on Marine Biodiversity, Extinction Risks for 90% of Species due to Unchecked Emissions."
        },
        {
          url: "/images/Biodiversity%20Crisis-Insects.webp",
          alt_text: "Insect Crisis & Ecosystem Impact: Slide highlights rapid insect extinction, ecosystem services, species decline causes, and diversity factors."
        },
        {
          url: "/images/Biodiversity%20Crisis-Soil.webp",
          alt_text: "Soil Degradation Impact: UN FAO stats on global soil health - degradation, erosion, and varied lifespans, highlighting urgent need for conservation."
        },
        {
          url: "/images/Biodiversity%20Crisis-Urban%20Heat%20Island.webp",
          alt_text: "Heat Islands & Health Risks: Urban heat disparities, public health dangers, and impacts on vulnerable communities highlighted on slide."
        },
        {
          url: "/images/Biophilia-Adults.webp",
          alt_text: "Nature vs. Mall Walk Impact: Slide contrasts mood effects, revealing significant improvements in self-esteem, tension reduction, and mood enhancement."
        },
        {
          url: "/images/Biophilia-Kids.webp",
          alt_text: "Green Impact on Student Outcomes: Tree cover linked to up to 13% variance, green space promotes 20% more self-discipline, counters deficiency."
        },
        {
          url: "/images/Cremation%20Statistics.webp",
          alt_text: "Cremation's Environmental Impact: Fossil fuels, CO2 emissions, pollutants, and acid rain implications highlighted on slide."
        },
        {
          url: "/images/Disposition%20Statistics.webp",
          alt_text: "Burial and Cremation Impacts: Slide exposes resource consumption - copper, hardwoods, embalming fluids, concrete, and steel. Cremation details revealed."
        },
        {
          url: "/images/How%20We%20Heal-Economic.webp",
          alt_text: "Economic Benefits of Walkability: Slide showcases property premiums, Central Park's value, street trees' impact, and High Line's neighborhood investment."
        },
        {
          url: "/images/How%20We%20Heal-Ecosystem.webp",
          alt_text: "Ecosystem Services Impact: $119B economic benefits, carbon storage, pollution removal highlighted - trees' vital role in urban well-being."
        },
        {
          url: "/images/How%20We Heal-Health.webp",
          alt_text: "Green Impact on Health: 10 trees/block = $10K income, 7 years younger; nature enhances memory by 20% and boosts perceived health."
        },
        {
          url: "/images/Remediation-1.webp",
          alt_text: "Phytoremediation Benefits: Cost-effective, eco-friendly, scalable, erosion prevention, improved soil fertility - a holistic pollution solution."
        },
        {
          url: "/images/Remediation-2.webp",
          alt_text: "Soil Remediation Costs: Cleansing low-contaminant soils cost $10-$35/ton, while off-site treatment may reach $200-$600/ton - lead soil comparison."
        },
        {
          url: "/images/Remediation-3.webp",
          alt_text: "Phytoremediation's Cost Advantage: 50% lower than excavation; 30% PAH reduction in 2 years, contrasts abandoned brownfields and cleanup estimates."
        },
        {
          url: "/images/Worker%20Safety.webp",
          alt_text: "Worker Safety Concerns: Embalmers face higher death rates, leukemia risk; ALS risk; maintenance workers suffer from COPD, respiratory, neurological issues."
        },
    ];
  
    const [isFullScreen, setIsFullScreen] = useState(false);

    React.useEffect(() => {
      const body = document.querySelector("body");
      if (isFullScreen) {
        body.style.overflow = "hidden";
        document.addEventListener("keydown", handleEscPress);
      } else {
        body.style.overflow = "";
        document.removeEventListener("keydown", handleEscPress);
      }
    }, [isFullScreen]);
  
    const handleToggleFullScreen = () => {
      setIsFullScreen(!isFullScreen);
    };

    const handleEscPress = (event) => {
      if (event.key === "Escape") {
        handleToggleFullScreen();
      }
    };

    const buttonStyle = {
        background: 'none',
        border: '0px',
    };
    
    const properties = {
        prevArrow: <button style={{ ...buttonStyle }}><TfiArrowCircleLeft style={{ color: '#808000', width: '35px', height:'35px', margin:'20px' }}/></button>,
        nextArrow: <button style={{ ...buttonStyle }}><TfiArrowCircleRight style={{ color: '#808000', width: '35px', height:'35px', margin:'20px' }}/></button>
    }
  
    return (
      <div className={`build-slider heal-slider${isFullScreen ? ' fullscreen fullscreen-heal' : ''}`}>
        <Slide {...properties}>
          {images.map((image, index) => (
            <div key={index} className={`${isFullScreen ? ' slider-fullscreen' : ''}`}>
              <div className={`slider-render-img-heal${isFullScreen ? ' img-fullscreen' : ''}`}
                style={{ backgroundImage: `url(${image.url})`}}
              />
             <img src={image.url} alt={image.alt_text} style={{ display: 'none' }}/>
            </div>
          ))}
        </Slide>
        <button onClick={handleToggleFullScreen} className={`${isFullScreen ? ' btn-fullscreen' : 'fullscreen-btn'}`} style={{ ...buttonStyle }}><TfiFullscreen style={{ color: '#808000', width: '30px', height:'30px', margin:'20px' }}/></button>
        {isFullScreen && <div className="overlay" onClick={handleToggleFullScreen} />}
      </div>
    );
};


function Heal() {
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Container className="healPage">
            <MetaData {... metaData}/>
            <h1 className="healHeadline">How We Heal at Life After Life</h1>
            <div className="promo-vid-wrapper">
                <iframe className="promo-vid"
                        src="https://www.youtube.com/embed/MMlPHqELaC8?autoplay=1&loop=1&playlist=MMlPHqELaC8&controls=1&rel=0&modestbranding=1">
                </iframe>
            </div>
               
            <div className="textBundleHeal1">
            <h2 className="subsection-title4 __right"> People have been harming the planet and the scars are showing.</h2>
            <p className="objective_summary_heal __right">However, we can be agents of change. Life is a beautiful journey enhanced by giving back. We are proud to provide an opportunity to leave the planet better than you found it.</p>
            </div>
                <img
                    src="/images/deer_sad.svg"
                    alt="Resilient Deer in Wildscape: Observing barren landscape, highlighting the need for habitat restoration and biodiversity conservation."
                    className="protectPlanetPic" 
                />
            
            <div className="textBundleHeal2">
                <h2 className="subsection-title4 __left"> Regeneration</h2>
                <p className="objective_summary_heal __left">Healing is a process of transformation into something new, yet resembling something old. When our patrons are laid to rest, they facilitate healing - transforming ravaged land into permanently preserved native ecosystems resembling the habitat that once belonged. This renewed habitat is enriched and supported by the organic matter they leave behind. 
                <p className='__center'>-</p>
                By becoming a Life After Life member, you become an integral piece of a restored ecosystem. You enable the sequestering of carbon and the creation of new life. You heal the world long after you’re gone!</p>
            </div>

            <img
                src="/images/cabin_removed_background.svg"
                alt="Celebrating with family at Life After Life: Active participation in meaningful services and events, cherishing memories and fostering community bonds"
                className="cabinPic"
            />
            <div className="textBundleHeal3">
            <h2 className="subsection-title4 __right"> Life, After Life</h2>
            <p className="objective_summary_heal __right">Through Life After Life, you can become your favorite tree, shrub, or perennial by customizing your plot with plants you love! The combination of your plot, with other patron's plots in aggregate, results in creation of a complete beautiful park that will be a perpetual part of the neighborhood you helped to heal, grow, and beautify.</p>

            </div>
                <img
                    src="/images/deer_happy.svg"
                    alt="Triumphant Deer at Waterfall: Nature's victory portrayed through a majestic deer amidst cascading waters, celebrating resilience and harmony."
                    className="coupleDog" 
                />
            <div className="textBundleHeal4">
                <a className= "page-btn-2" href="/join" >Let's Start Healing!</a>
            </div>
        </Container>
        <div><SliderShowHeal/></div>
        </div>
    )
}

export default Heal
