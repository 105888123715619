import React from 'react'
import './whoarewe.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { Container, Row, Col } from 'react-bootstrap';
import MetaData from "./metaData/metaData";

const teamInfo = [
    {
        "fullname": "Jacob Tannenbaum",
        "name": "Jacob",
        "photo": '/images/Jacob_portrait.webp',
        "alttext": "Jacob Tannenbaum, Founder of Life After Life",
        "linkedin": "https://www.linkedin.com/in/jacob-tannenbaum",
        "description": "...Loves nature. Jacob is passionate about restoring the world's ecosystems \
                        and making nature a part of everyday life for more people. Jacob is one of those people who calls his house \
                        plants pets and spends his time daydreaming about parks."
    },
    {
        "fullname": "Shaily Patil",
        "name": "Shaily",
        "photo": '/images/Shaily_portrait.webp',
        "alttext": "Shaily Patil, Founder of Life After Life",
        "linkedin": "https://www.linkedin.com/in/shaily-patil",
        "description": "...Loves building communities. Shaily firmly believes that a \
                        coming together for this beautiful planet is the biggest source of happiness in \
                        the world. Here at Life After Life, Shaily strives to bring this happiness to you and your loved ones."

    },
    {
        "fullname": "Jeremiah Swain",
        "name": "Jeremiah",
        "photo": '/images/Jeremiah_portrait.webp',
        "alttext": "Jeremiah Swain, Founder of Life After Life",
        "linkedin": "https://www.linkedin.com/in/jcswain",
        "description": "...Loves people. Jeremiah loves understanding and creating products \
                        and experiences at nuanced cultural intersections. Hospitality is the \
                        edrock of Jeremiah's professional experience and looks \
                        forward to applying it creatively with Life After Life."

    },
    {
        "fullname": "Rachel Sklar",
        "name": "Rachel",
        "photo": '/images/Rachel_Sklar.webp',
        "alttext": "Rachel Sklar, Life After Life",
        "linkedin": "https://www.linkedin.com/in/rsklar/",
        "description": "...Loves nonprofits. Rachel is an attorney who has spent her career \
                        building nonprofits. She is passionate about improving the quality of \
                        life for communities across the country through charitable foundations."

    },
    {
        "fullname": "Sam",
        "name": "Sam",
        "photo": 'images/Sam_Tannenbaum.webp',
        "alttext": "Sam, Life After Life",
        "linkedin": "https://www.linkedin.com/in/samuel-tannenbaum-leed-ap-a2131765/",
        "description": "...Loves design. Sam is an architect who shapes spaces into extraordinary \
                        experiences. He instills a symbiosis between the natural elements and built \
                        environment into each of his projects in pursuit of a healthier Earth."

    },
    {
        "fullname": "Haocheng Shen",
        "name": "Haocheng",
        "photo": '/images/Haocheng Shen Headshot.webp',
        "alttext": "Haucheng Shen, Life After Life",
        "linkedin": "https://www.linkedin.com/in/haocheng-shen-5777ab1ab/",
        "description": "...Loves technology. Haocheng brings a passion for for building innovative \
                        and accessible tools and a penchant seamless user experiences together in pursuit \
                        of immaculate digital experiences."

    },
    {
        "fullname": "Kaycee Sapp",
        "name": "Kaycee",
        "photo": '/images/Kaycee Sapp Headshot.jfif',
        "alttext": "Kaycee Sapp, Life After Life",
        "linkedin": "https://www.linkedin.com/in/kaycee-sapp/",
        "description": "...Loves connecting people. Kaycee utilizes her gregarious nature, knack for storytelling,\
                        and design talents to get the word out about causes with real world impacts. \
                        Send her a message, she’d love to chat."

    },
    {
        "fullname": "Michael Tomlan",
        "name": "Michael",
        "photo": '/images/Michael_Tomlan.webp',
        "alttext": "Michael Tomlan, Life After Life",
        "linkedin": "https://www.linkedin.com/in/michael-tomlan-37a9329/",
        "description": "...Loves historic preservation and planning. Michael brings decades of experience in the \
                        caretaking and revitalization of neglected spaces, including traditional cemeteries. His work \
                        has transformed communities."

    },
    {
        "fullname": "Paul Tannenbaum",
        "name": "Paul",
        "photo": '/images/Paul_Tannenbaum.webp',
        "alttext": "Paul Tannenbaum, Life After Life",
        "linkedin": "https://www.linkedin.com/in/paul-tannenbaum-8718481/",
        "description": "...Loves public partnerships. Paul understands how to navigate the space between central planning \
                        and private enterprise. He has guided many multistate operations to success as they scale."

    }
]

const metaData = {
	title: "Our Team | Life After Life - We Build Parks", 
	description: "Meet the visionary team behind Life After Life, a groundbreaking sustainable deathcare company that creates eco-friendly memorial parks. LAL is committed to placemaking, sustainability, and bringing parks to underserved areas. Discover our innovative products powering our conservation cemetery parks - from cloud memorials and eco-friendly funerals to mushroom burial and human composting. Our team members are passionate about conservation, biodiversity, and economic development, and we're proud to build modern memorial parks that bring renewal and inclusivity. Learn why Life After Life Foundation is the future of cemetery, funeral, and burial services. Check our ‘Team’ page out.",
	author: "Life After Life", 
	type: "website", 
	image: "/images/Life After Life Logo Blended 6 Large size smaller file.webp", 
	url: "lifeafter.life/whoarewe"
}

function Profile(props){
    return(
        <Col sm={12} md={6} lg={4}>
        <div className="toggleBio">
            <div className="bundle">
            <div className="logoBundle">
            <div className="togglePic"><img src={props.member["photo"]} alt={props.member["alttext"]} className='bioPics'/></div> 
                <div className="contactBundle">
                    <div className="titleBundle">
                        <h1 className="bioTitle Name">{props.member["name"]}</h1>
                        <a href={props.member["linkedin"]} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faLinkedin} className='linkedin'/></a>
                    </div>
                </div>
            </div>
            <div className="textBundle">
                <h1 className="bioDescription">{props.member["description"]}</h1>
            </div>
            <img class="winterPlants" alt="Life After Life's Memorial Treescape: A serene landscape with trees in remembrance, nurturing eco-friendly values and lasting legacies." src="/images/winter_plants_watercolor.webp" />
            </div>
            </div>
        </Col>
    );
}

function Whoarewe() {
    let rows = []
    for (var i = 0; i < teamInfo.length; i++){
        rows.push(<Profile member = {teamInfo[i]}></Profile>)
    }
    
    return (
        <Container id="LifeAfterLifeWhoAreWePage">
            <MetaData {... metaData}/>
            <div className="bios">
                <h1 className='team-heading'>Our Team</h1>
                <Row className="bioBundles">{rows}</Row>
            </div>   
     </Container>
    )
}

export default Whoarewe
