import React from 'react'

// import { faCartArrowDown } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import './navbar.css';
import { withRouter } from 'react-router-dom';
// import NavbarCollapse from 'react-bootstrap/NavbarCollapse';

const logo5 = '/images/lal-logo-svg.svg';
const cart = '/images/CART.webp';
function Navigation(props) {
    
    return (
        <Container>
        <Navbar collapseOnSelect className="NavbarItems" expand="lg" fixed='top'>
            <Navbar.Brand href="/">
                <div className="completelogo">
                    <img src={logo5} alt="Life After Life Logo" className="logoImg" />
                    <h5 className="logoText">Life <br /> After <br /> Life</h5>
                </div>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" >

            </Navbar.Toggle>
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto"></Nav>
                <Nav className="whoweare">

                    <Navbar className="pageTitles">
                        <Container fluid>
                            <Navbar.Brand></Navbar.Brand>
                            <Navbar.Toggle className="pageTitles" aria-controls="navbar-light-example" />
                            <Navbar.Collapse id="navbar-light-example">
                                <Nav class="nav-title">
                                    <NavDropdown

                                        className="dropdown"
                                        id="dropdown-item"
                                        title="Who Are We?"
                                        renderMenuOnMount={true}
                                        menuVariant = 'dark'
                                    >
                                        <NavDropdown.Item id="dropdown-item" href="/whoarewe">Our Team</NavDropdown.Item>
                                        <NavDropdown.Item id="dropdown-item" href="/services">Member Benefits</NavDropdown.Item>
                                        <NavDropdown.Item id="dropdown-item" href="/about-us">About Us</NavDropdown.Item>
                                        <NavDropdown.Item id="dropdown-item" href="/build">Why Build?</NavDropdown.Item>
                                        <NavDropdown.Item id="dropdown-item" href="/heal">How We Heal</NavDropdown.Item>
                                        <NavDropdown.Item id="dropdown-item" href="/whatswrong">What's Wrong?</NavDropdown.Item>
                                        <NavDropdown.Item id="dropdown-item" href="/articles">Press</NavDropdown.Item>
                                        {/* <NavDropdown.Item id="dropdown-item" href="/blog">Blog</NavDropdown.Item> */}
                                    </NavDropdown>
                                </Nav>
                                
                                <Nav className="nav-title">
                                    <NavDropdown

                                     
                                        id="dropdown-item"
                                        title="Benefactors"
                                        renderMenuOnMount={true}
                                        
                                    >
                                        <NavDropdown.Item id="dropdown-item" href="/donations">Donate</NavDropdown.Item>
                                        <NavDropdown.Item id="dropdown-item" href="/invest">Invest in Parks</NavDropdown.Item>
                                        <NavDropdown.Item id="dropdown-item" href="/land">Help Find Land</NavDropdown.Item>
                                        <NavDropdown.Item id="dropdown-item" href="/volunteer">Volunteer</NavDropdown.Item> 
                                        {/* <NavDropdown.Item id="dropdown-item" href="/learn">Help Us Learn</NavDropdown.Item> */}
                                    </NavDropdown>
                                </Nav>

                                <Nav className="nav-title">
                                    <NavDropdown

                                     
                                        id="dropdown-item"
                                        title="Patrons"
                                        renderMenuOnMount={true}
                                    >
                                        <NavDropdown.Item id="dropdown-item" href="/join">Become a Lifer</NavDropdown.Item>
                                        <NavDropdown.Item id="dropdown-item" href="/store">Gift Shop</NavDropdown.Item>
                                        <NavDropdown.Item id="dropdown-item" href="/learn">Help Us Learn</NavDropdown.Item>
                                        <NavDropdown.Item id="dropdown-item" href="/stayinformed">Stay Informed</NavDropdown.Item>
                                        <NavDropdown.Item id="dropdown-item" href="/community">Community</NavDropdown.Item>
                                        <NavDropdown.Item id="dropdown-item" href="/education">Education</NavDropdown.Item>

                                        {/* <NavDropdown.Item id="dropdown-item" href="/events">Events</NavDropdown.Item> */}
                                        {/* <NavDropdown.Item id="dropdown-item" href="/volunteer">Volunteer</NavDropdown.Item> */}
                                        <Nav className="nav-title">
                                   {/* <NavDropdown

                                     
                                        id="dropdown-item"
                                        title="Events"
                                        renderMenuOnMount={true}
                                    >
                                        <NavDropdown.Item id="dropdown-item" href="/community">Community</NavDropdown.Item>
                                        <NavDropdown.Item id="dropdown-item" href="/education">Education</NavDropdown.Item>
                      
        
                                </NavDropdown> */}
                                </Nav>
                        

        
                                    </NavDropdown>
                                </Nav>
                                {/* <Nav.Link href="/articles" className="pageTitles" style={{color: "#808000"}}>Press</Nav.Link>  */}
                                <Nav.Link href="/blog" className="pageTitles" style={{ color: "#808000" }}>Blog</Nav.Link>
                                <Nav.Link eventKey={2} href="/contactus" className="pageTitles" style={{ color: "#808000" }}>Contact Us</Nav.Link>
                                {/* <Nav.Link href="/donate" className="pageTitles" style={{ color: "#808000" }}>Donate</Nav.Link> */}
                                <Nav.Link href="/join" className="reservations" >Join<img src={cart} alt="green wheelbarrow"className="r-icon"/></Nav.Link>
                         
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                </Nav>


            </Navbar.Collapse>

        </Navbar>

    </Container>
    )
}
export default withRouter(Navigation);
