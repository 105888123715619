import React from 'react';
import { Container } from 'react-bootstrap';
import './events.css'


function Events() {
    return(
        <Container className="events-container">
        <head>
            <title>
                Events
            </title>
        </head>
        <body>
        <div className="events-background">
            <div className="eventsMain">
                <h1 className="events heading">Coming Soon: Events</h1>
                <p className="events context"> Life After Life is building modern memorial parks in urban spaces. If you’re like us and love nature and sustainability, then this IS the last place you’ll want to be.
                </p>
            </div>
  
        </div>
    
        </body>
    </Container>

    )

}
export default Events;