import React from 'react'
// import Select from "react-select";
import { useState }  from "react";
import './blog.css';
import { Container } from 'react-bootstrap'
import { Modal} from "react-bootstrap";
import articleText from "./articles/articles.json";
import MetaData from "./metaData/metaData";

const metaData = {
	title: "Blog | Life After Life - Our Thoughts", 
	description: "Life After Life shares expertise and insights with our community to inspire and help people live more sustainably. Our 'Blog' page features a range of articles and resources on green burial, parks, sustainability, remediation, and more. Our team of experts and guest contributors offer practical tips, inspiring stories, and thought-provoking perspectives on creating personalized, sustainable end-of-life experiences. Whether you're looking for ideas on how to plan a green funeral or want to explore the latest trends in deathcare, our blog has something for you. We're committed to providing resources that empower individuals and communities to make informed choices about deathcare and to create spaces that promote healing, growth, and connection. Visit our 'Blog' page now to stay up-to-date with the latest news and insights from LAL Parks.",
	author: "Life After Life", 
	type: "website", 
	image: "/images/Life After Life Logo Blended 6 Large size smaller file.webp", 
	url: "lifeafter.life/blog"
}


function News(reverse_id){
	const id = articleText.articles.length - reverse_id - 1;
    var date = new Date(articleText.articles[id].date)
    var year = date.getFullYear()
    const month = date.toLocaleString('en-US', { month: 'short' });
    
    return(
		<div class="ct-blog col-sm-6 col-md-4">
		<a href={"/article/" + articleText.articles[id].title} rel="noreferrer" style={{textDecoration: 'none'}}>
			<div class="inner">
				<div class="fauxcrop">
				<img src = {articleText.articles[id].img} alt = {articleText.articles[id].alt} />				
				</div>
				<div class="ct-blog-content">
					<div class="ct-blog-date">
						<strong>{year}</strong>
                        <span>{month}</span>
					</div>

                    <h3 className='ct-blog-header'>{articleText.articles[id].heading}</h3>
				</div>
			</div>
			</a>
		</div>
    );
}

const tagList = ['Biodiversity', 'Climate Change', 'Deathcare', 'Culture & Religion'];
const options = ['Biodiversity', 'Climate Change', 'Deathcare', 'Culture & Religion', 'All'];

function Blogs() {
	const [showCount, setShowCount] = useState(9);
	const [smShow, setSmShow] = useState(false);
	const [selectedOption, setSelectedOption] = useState(['All']);
	const [tags, setTags] = useState(['Biodiversity', 'Climate Change', 'Deathcare', 'Culture & Religion']);
  
	function overlap(a) {
		var result = false
		a.tags.forEach(elementA => {
			tags.forEach(elementB => {
				if (elementA === elementB){
					result = true;
				}
			})
		});
		return result;
	}

	var blogs = articleText.articles.filter(a => overlap(a)).map((a) => News(a.id)).slice(0, showCount);
		
		const showMore = () => {
			if (showCount <= articleText.articles.length){
				setShowCount(showCount + 9);
			}else{
				setSmShow(true);}
		};

	function SearchBar() {

		function handleSelect(e) {
			const selectedValue = e.target.value;
			setSelectedOption(selectedValue);
			if (selectedValue === "All"){
				setTags(tagList);
			}
			else{
				setTags([selectedValue]);
			}
		}
	
		function handleTagClose(tag) {
			const newtag = tags.filter((t) => t !== tag);
			setTags(newtag);
			if (newtag.length === 1){
				setSelectedOption(newtag);
			}
		}
		  
	
		return (
			<div>
				<div className='category__wrapper' style={{ display: 'flex', alignItems: 'center' }}>
					<select 
						className='category_dropdown' value={selectedOption} onChange={handleSelect}>
						<option value = "" disabled selected>Select Category</option>
						{options.map((option) => (
							<option className='category-option' key={option} value={option}>
								{option}
							</option>
						))}
					</select>
				</div>


				<div className='tag__wrapper'>
					{tags.map((tag) => (
						<div key={tag} className='category__tag'>
							<div>{tag}</div>
							<button className='category__X'onClick={() => handleTagClose(tag)}>X</button>
						</div>
					))}
				</div>
			</div>
		);
	}
    return (
		<Container className="articlesPage">
		<MetaData {... metaData}/>
		<h2 class="news-title">
			Blog
		</h2>
		<SearchBar />
		<div class="row">
		{blogs}
		<button class="btn-news" onClick={showMore}>VIEW MORE BLOGS </button>
		<Modal
            size="md"
            show={smShow}
            onHide={() => setSmShow(false)}
            aria-labelledby="example-modal-sizes-title-sm"
        >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
			    <h2 className="btn-newsModal">You're all caught up!</h2>
		    </Modal.Body>
        </Modal>
	
	</div>
</Container>
    )
}

export default Blogs
