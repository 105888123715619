import React from 'react'
import { useState }  from "react";
import './Articles.css';
import { Container } from 'react-bootstrap'
import { Modal} from "react-bootstrap";
import MetaData from "./metaData/metaData";

const metaData = {
	title: "Press | Life After Life - Recent News", 
	description: "At Life After Life, we're proud to be recognized as a leader in the sustainable funeral industry. Our 'In the News' page features highlights of our recent media coverage, including awards, interviews, articles, and features in major news outlets. From discussing the benefits of eco-friendly funerals to sharing our vision for a more equitable future through green end-of-life services, our team of experts and partners are making headlines for all the right reasons. We're committed to creating spaces that promote healing, growth, and community, while reversing harm to the environment. Our innovative approach to deathcare is transforming the industry and helping families and communities create meaningful, personalized end-of-life experiences. Visit our 'In the News' page now to see what the media is saying about LAL Parks.",
	author: "Life After Life", 
	type: "website", 
	image: "/images/Life After Life Logo Blended 6 Large size smaller file.webp", 
	url: "lifeafter.life/articles"
}
const moonshot = '/images/Moonshot-Competition.webp';
const NYBPC = '/images/NYBPC.webp';
const hospitality = '/images/Hospitality-Competition.webp';
const washington = './images/washington_post.webp'
const icon = './images/cornell_icon_innovation.webp'
const LARCH = './images/Landscape Architecture MAG APRIL 2023 Cover.webp'
const AcceliCITY = './images/Life After Life - Award - QBE AcceliCITY Top 100 Resilience Solutions.webp'
const EPABrownfields = './images/EPA Brownfields Conference 2023 Detroit.webp'
const PioneeerPressTCAAP = './images/Twin Cities - Pioneer Press.webp'

function Articles() {
	const [smShow, setSmShow] = useState(false);
    return (
		<Container className="articlesPage">	
		<MetaData {... metaData}/>
		<h2 class="news-title">
			Recent News
		</h2>
		<div class="row">

			<div class="ct-blog col-sm-6 col-md-4">
			<a href="https://www.twincities.com/2023/08/30/tcaap-parcel-arden-hills-for-sale-natural-cemetery/" target="_blank" rel="noreferrer" style={{textDecoration: 'none'}}>
				<div class="inner">
					<div class="fauxcrop">
					<img src={PioneeerPressTCAAP} alt="Life After Life was featured in the Twin Cities Pioneer Press discussing endeavor to build a living memorial park at the TCAAP brownfield site"/>
						
					</div>
					<div class="ct-blog-content">
						<div class="ct-blog-date">
							<span></span><strong>2023</strong>
						</div>
						<h3 class="ct-blog-header">
								
							Featured in The Twin Cities Pioneer Press
						</h3>
					</div>
				</div>
				</a>
			</div>
	    
			<div class="ct-blog col-sm-6 col-md-4">
			<a href="https://brownfields2023.org/sessions/#_session-2388937" target="_blank" rel="noreferrer" style={{textDecoration: 'none'}}>
				<div class="inner">
					<div class="fauxcrop">
					<img src={EPABrownfields} alt="Life After Life had a poster presentation at the EPA Bownfields Conference 2023 in Detroit, Michigan"/>
						
					</div>
					<div class="ct-blog-content">
						<div class="ct-blog-date">
							<span></span><strong>2023</strong>
						</div>
						<h3 class="ct-blog-header">
								
							Brownfields Conference 2023 Presenter
						</h3>
					</div>
				</div>
				</a>
			</div>
	    
			<div class="ct-blog col-sm-6 col-md-4">
			<a href="https://leadingcities.org/accelicity/257e68d7-56e0-4346-9cd3-f7f2fc66b407" target="_blank" rel="noreferrer" style={{textDecoration: 'none'}}>
				<div class="inner">
					<div class="fauxcrop">
					<img src={AcceliCITY} alt="Life After Life Winning AcceliCITY Top 100 Resilience Solution"/>
						
					</div>
					<div class="ct-blog-content">
						<div class="ct-blog-date">
							<span></span><strong>2023</strong>
						</div>
						<h3 class="ct-blog-header">
								
							AcceliCITY Top 100 Resilience Solution
						</h3>
					</div>
				</div>
				</a>
			</div>
	    
			<div class="ct-blog col-sm-6 col-md-4">
			<a href="http://bt.royle.com/publication/?m=44306&i=785366&p=26&ver=html5/" target="_blank" rel="noreferrer" style={{textDecoration: 'none'}}>
				<div class="inner">
					<div class="fauxcrop">
					<img src={LARCH} alt="Life After Life Published in Landscape Architecture Magazine"/>
						
					</div>
					<div class="ct-blog-content">
						<div class="ct-blog-date">
							<span></span><strong>2023</strong>
						</div>
						<h3 class="ct-blog-header">
								
							Published in Landscape Architecture Magazine
						</h3>
					</div>
				</div>
				</a>
			</div>
			
			<div class="ct-blog col-sm-6 col-md-4">
			<a href="https://www.washingtonpost.com/climate-environment/2023/01/31/green-funeral-options-cremation-burial/" target="_blank" rel="noreferrer" style={{textDecoration: 'none'}}>
				<div class="inner">
					<div class="fauxcrop">
					<img src={washington} alt="Life After Life Published in the Washington Post"/>
						
					</div>
					<div class="ct-blog-content">
						<div class="ct-blog-date">
							<span></span><strong>2023</strong>
						</div>
						<h3 class="ct-blog-header">
								
							Published in the Washington Post
						</h3>
					</div>
				</div>
				</a>
			</div>

			<div class="ct-blog col-sm-6 col-md-4">
			<a href="https://business.cornell.edu/hub/2021/06/22/at-a-glance-2021-sha-celebration-week/" target="_blank" rel="noreferrer" style={{textDecoration: 'none'}}>
				<div class="inner">
					<div class="fauxcrop">
					<img src={icon} alt="Life After Life winning Icon and Innovator Awards"/>
						
					</div>
					<div class="ct-blog-content">
						<div class="ct-blog-date">
							<span></span><strong>2021</strong>
						</div>
						<h3 class="ct-blog-header">
								
							Icon and Innovator Awards
						</h3>
					</div>
				</div>
				</a>
			</div>
			
			<div class="ct-blog col-sm-6 col-md-4">
			<a href="https://www.lifechanginglabs.com/network/" target="_blank" rel="noreferrer" style={{textDecoration: 'none'}}>
				<div class="inner">
					<div class="fauxcrop">
					<img src={moonshot} alt="Life After Life winning 1st Place & Moonshot Award - Life Changing Labs"/>
						
					</div>
					<div class="ct-blog-content">
						<div class="ct-blog-date">
							<span></span><strong>2021</strong>
						</div>
						<h3 class="ct-blog-header">
								
							1st Place & Moonshot Award - Life Changing Labs
						</h3>
					</div>
				</div>
				</a>
			</div>
			
			<div class="ct-blog col-sm-6 col-md-4">
			<a href="https://nybpc.org/news/2021/5/11/winners-of-2021-new-york-business-plan-competition-announcednbsp" target="_blank" rel="noreferrer" style={{textDecoration: 'none'}}>
				<div class="inner">
					<div class="fauxcrop">
					<img src={NYBPC} alt="Life After Life winning 2nd Place - New York Business Plan Competition, Infrastructure, First Responder, & Defense Prize Category- NYBPC"/>
						
					</div>
					<div class="ct-blog-content">
						<div class="ct-blog-date">
							<span></span><strong>2021</strong>
						</div>
						<h3 class="ct-blog-header">
						2nd Place - New York Business Plan Competition, Infrastructure, First Responder, & Defense Category
							
						</h3>
					</div>
				</div>
				</a>
			</div>
			<div class="ct-blog col-sm-6 col-md-4">
			<a href="https://news.cornell.edu/stories/2021/05/green-cemetery-wins-hospitality-business-pitch-contest" target="_blank" rel="noreferrer" style={{textDecoration: 'none'}}>
				<div class="inner">
					<div class="fauxcrop">
					<img src={hospitality} alt="Life After Life winning 1st Place - Cornell University Hospitality Business Plan Competition"/>
						
					</div>
					<div class="ct-blog-content">
						<div class="ct-blog-date">
							<span></span><strong>2021</strong>
						</div>
						<h3 class="ct-blog-header">
						1st Place - Cornell University Hospitality Business Plan Competition
							
						</h3>
					</div>
				</div>
				</a>
			</div>
		
		
		<button class="btn-news" onClick={()=>{
			setSmShow(true)
		}}>VIEW ALL NEWS </button>
			<Modal
        size="md"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          
        </Modal.Header>
        <Modal.Body>
			<h2 className="btn-newsModal">You're all caught up!</h2>
		</Modal.Body>
      </Modal>
	</div>
</Container>
    )
}

export default Articles
