import {React} from 'react'
import Collapsible from 'react-collapsible';
import JotformEmbed from 'react-jotform-embed';
import './join.css';
import { Container, Row, Col } from "react-bootstrap";
import { BiPlus } from "react-icons/bi";
import MetaData from "./metaData/metaData";

const metaData = {
	title: "Become a Member | Life After Life Foundation - One Last Good Deed", 
	description: "At Life After Life, we are using sustainable deathcare to bring new life to distressed communities and ecosystems. As a nonprofit organization, we rely on the generosity of our supporters to fund our projects and programs. By becoming a member or making a donation, you can help us continue our important work and make a positive impact on the world while resting assured that a sustainable final resting place awaits you when your time is over. Our 'Checkout' page provides a simple, secure way to become a member through either one-time-gift or subscription donations. With your support, we can build a better, more sustainable future for all. Visit our 'Checkout' page now to make your donation and join our beautiful community of changemakers.", 
	author: "Life After Life", 
	type: "website", 
	image: "/images/join_rendering.webp", 
	url: "lifeafter.life/join"
}

const benefit = '/images/Giving-Levels-and-Benefits.webp';
const checkoutRender = '/images/Life After Life - Join Page - Boardwalk Birdseye 2.0 - 3 to 1.webp';

function Collapse(){
    const text = 
        <p className="collapsible--text">
            We encourage Lifers in their individual pursuits towards a sustainable life on their way to Full Lifer Membership and a secured sustainable end. Check out our <span className='text__under-line'>table</span> for some of the landmark gifts we provide along the path.
        </p>;
    const plus = <BiPlus className= "--plus-sign" size = "45px"/>;
    // const minus = <BiMinus className= "--plus-sign__opened" size = "45px"/>;
    const minus = <BiPlus className= "--plus-sign --plus-sign__opened" size = "45px"/>;
        
    return <Collapsible 
            className = "benefit-collapsible" 
            openedClassName = "benefit-collapsible" 
            trigger = {[plus, text]}
            triggerWhenOpen = {[minus, text]}
            >
            <img src={benefit} alt="Empowering Giving Levels at Life After Life: Unveiling benefits for supporters, fostering sustainability and community impact."/>
        </Collapsible>;
}

function Join() {
  return (
     <div className="checkoutPage">
        <MetaData {... metaData}/>
        <div id ="checkoutTitle">
					<img className='join-render--img' src={checkoutRender} alt='Life After Life Park: An inspiring depiction of a sustainable oasis, embracing green initiatives, inclusivity, and communal harmony.' />
					<div className='join-render--text-wrapper'>
						<div className='join-render--top-border'/>
						<h1 className='join-render--text'>Become a Member Today & Help Build Parks!</h1>
						<div className='join-render--bottom-border'/>
					</div>
        </div>
				<Container>
          <div id ="checkoutHeader">
            <p> 
							One new acre of park space, in every city, every year, forever - that’s our goal. 
	  
	  						By becoming a supporter you are making a direct
							impact in the lives of people and the health of our ecosystem, for generations to come. Full Life After Life members rest assured that a
							sustainable final resting place will be awaiting them when their day comes
						</p>
          </div>
					< Collapse/>
          <div className= "checkout_large">
						<Row sm={{ order: 2 }} className= "checkout_form1">
							<Col >
								<JotformEmbed className="Gift_jot"src="https://form.jotform.com/222505282812147"/>
							</Col>
						</Row>
            <p></p>
						<Row sm={{ order: 2 }} className= "checkout_form2">
							<Col >
								<JotformEmbed className="donor_jot"src="https://form.jotform.com/222514597856164"/>
							</Col>
						</Row>
          </div>
          <div id ="checkoutAfter">
            <h2 id = "contribute_header">Your Contributions Will...</h2>
            <div className="checkoutTile">
            	<ul class = "contributions">
            		<li class= "contribution">
            			<img class ="checkout_Icons" src = "/images/build_parks.webp" />
            			<p class = "tiles">
            				<h3 class ="contribution_title">Build Parks</h3> 
										Your gift directly results in the building of an 30 square feet of green public space for people and nature to thrive in.
            			</p>
            		</li>
            		<li class= "contribution">
            			<img class ="checkout_Icons" src = "/images/restore.webp"></img>
            			<p class = "tiles">
            				<h3 class ="contribution_title">Restore Ecology</h3> 
										Your contributions allow us
										to take dilapidated land and
										remediate it into ecologically
										healthy biomes in the fight
										to protect biodiversity from
										extinction.
            			</p>
            		</li>
            <li class= "contribution">
            <img class ="checkout_Icons" src = "/images/resilience.webp"></img>
            <p class = "tiles">
            <h3 class ="contribution_title">Create Resilience</h3> 
            Our cities face serious challenges as climate change worsens. Our nature-based solutions reduce flooding, decrease
            air and water pollution, and
            mitigate urban heat islands.
            </p>
            </li>
            <li class= "contribution">
            <img class ="checkout_Icons" src = "/images/educate.webp"></img>
            <p class = "tiles">
            <h3 class ="contribution_title">Educate & Connect</h3>  
            Together with communities,
            governments, schools, and other nonprofits, we educate and
            inspire visitors with nature,
            and help them establish environmentally conscious practices in their daily lives.
            </p>
            </li>
            </ul>
            </div>
            <div class= "checkoutTile">
            <ul class = "contributions">

            <li class= "contribution">
            <img class ="checkout_Icons" src = "/images/foster.webp"></img>
            <p class = "tiles">
            <h3 class ="contribution_title">Foster Community</h3> 
            By creating more third spaces
            where communities can congregate freely between work
            and home, you help improve
            the quality of life in our urban
            spaces for all.
            </p>
            </li>
            <li class= "contribution">
            <img class ="checkout_Icons" src = "/images/fight.webp"></img>
            <p class = "tiles">
            <h3 class ="contribution_title">Fight Climate Change</h3> 
            The redevelopment of natural
            habitat holds carbon in the
            soil and biomass. Your membership directly results in an
            estimated average of 10 kg of
            carbon sequestered every year.
            </p>
            </li>
            <li class= "contribution">
            <img class ="checkout_Icons" src = "/images/protect_nature.webp"></img>
            <p class = "tiles">
            <h3 class ="contribution_title">Protect Nature</h3>
            The parks you help make are
            placed into conservation easements in order to protect the
            habitat you’ve created and the
            legacy you leave behind. 
            </p>
            </li>
            <li class= "contribution">
            <img class ="checkout_Icons" src = "/images/catalyze.webp"></img>
            <p class = "tiles">
            <h3 class ="contribution_title">Catalyze Opportunity</h3> 
            By building new amenities, you
            help us be a catalyst of something good in underserved
            neighborhoods, advancing
            the economic opportunities of
            those who live there around.
            </p>
            </li>
            </ul>
            </div>
            </div>    
					</Container>      
        </div>
    )
}

export default Join
