import React from "react";

const ShowPassword = (props) => {
	const { showPassword, setShowPassword } = props;

	const toggleShowPassword = (e) => {
		e.preventDefault();
		setShowPassword((prev) => !prev);
	};

	return (
		<div style={{ cursor: "pointer" }} onClick={toggleShowPassword}>
			{showPassword ? "Hide Password" : "Show Password"}
		</div>
	);
};

export default ShowPassword;
