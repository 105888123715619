import React from 'react';
import './wrong.css';
import { Container } from 'react-bootstrap'
import MetaData from "./metaData/metaData";

const metaData = {
	title: "What’s Wrong? | Life After Life - Earth Needs Better", 
	description: "At Life After Life, we recognize that there are many problems with the current deathcare business model and the municipally funded park system. We need to make a change. Our 'What's Wrong' page highlights the issues with traditional deathcare cemetery, cremation, and funeral practices, including their negative impacts on the environment and the lack of transparent, personalized, meaningful experiences for families and friends of those who have passed away. Our approach to deathcare is an opportunity for members to give ‘One Last Good Deed’. We transcend the antiquated traditional cemeteries to create a more sustainable, equitable, and inclusive future for end-of-life services. LAL offers a range of green, funeral products, burial services, and memorialization options that are designed to minimize harm to the environment and promote healing and growth. Discover why the current deathcare system is failing customers and how Life After Life’s Parks are leading the way in creating a better, more sustainable alternative. Visit our 'What's Wrong' page now.",
	author: "Life After Life", 
	type: "website", 
	image: "/images/Life After Life Park - One Last Good Deed.webp", 
	url: "lifeafter.life/whatswrong"
}

const waste = '/images/Life_After_Life_-_Whats_Wrong_-_Material_Waste_in_Funeral_Industry.webp'
const abandonment = '/images/Life_After_Life_-_Whats_Wrong_-_Cemetery_Abandonment.webp'
const cremation = '/images/Life_After_Life_-_Whats_Wrong_-_The_problem_with_cremation.webp'

function Wrong() {
    return (
        <Container id="LifeAfterLifeWrongPage">
            <MetaData {... metaData}/>
             
            <div className='wrongTitle'>What's Wrong?</div>
            <div className='wrongBackgroundColor'></div>

            <div class="container-wrong">
                <img src={waste} alt="Wasteful Funeral Industry: Material waste from traditional practices, urging eco-conscious alternatives and sustainability in the end-of-life process." className="wrong-pic" />
                <div className="wrongText">
                    <h1 className='subsection-title-wrong text-right'>What is happening underground in a traditional cemetery?</h1>
                    <p className="wrongParagraph text-right"> In the United States alone, traditional cemetery practices consume over 1,100
                        acres annually, bury approximately 20 million feet of hardwood boards, 64.5 thousand tons of steel, 17 thousand tons of copper, 1.6 million
                        tons of concrete, 4.3 million gallons embalming fluid, and emit 25,000 metric tons of carbon along the way. For scale, that is enough
                        materials to construct 4,180 homes, and enough formaldehyde to fill a 8 olympic-sized swimming
                        pools - every year.</p>
                </div>

            </div>
            
            <div class="container-value">
                <div className="valueText">
                    <div className='subsection-title-wrong text-left'>Aren't cemeteries good for nature? </div>
                    <p className="wrongParagraph text-left">Traditional cemeteries are low in environmental services value. Lawn cemeteries provide
                            minimal air quality improvement, stormwater retention, and habitat support. Furthermore, their maintenance demands
                            are not environmentally friendly. They require near-constant mowing, irrigation, fertilizer, and weed and pest chemicals to
                            keep the monoculture turf healthy. The compacted soil from heavy machinery use is not conducive to healthy plants or stormwater
                            absorption. The tombstones and monuments require regular bleaching to keep mold, algae, and lichens off. Heavy equipment is
                            needed regularly to replace fallen headstones, crumbing mausoleums, and decaying monuments as the ground inevitably and continuously shifts over time. </p>
                </div>
                <img src={abandonment} alt="Abandoned Cemetery: A haunting reminder of neglect, calling for restoration and sustainable practices to preserve its heritage." className="wrong-pic" />
            </div>

            <div class="container-cremation">
                <img src={cremation} alt="Traditional Cemetery: A solemn resting place, seeking sustainable transformations for a greener and eco-friendly future" className="wrong-pic" />
                <div className="cremationText">
                    <div className='subsection-title-wrong text-right'>Ok, But What About Cremation?</div>
                    <p className="wrongParagraph text-right">Glad you asked! Traditional cremation accounts for the emission of roughly 360,000
                        metric tons of CO2 annually. For scale, that is the equivalent of burning 30 gallons of gasoline per cremation! Cremation
                        also releases other dangerous pollutants into the air like mercury. During the early days of the COVID-19 pandemic, air quality in some cities became so poor that they had to stop cremations.</p>
                    <p className="wrongParagraph text-right">The planet and people demand a more affordable and sustainable solution for end-of-life practices
                        that are in line with today’s religious and cultural values.</p>
                        <br></br><br></br> 
                </div>
                
                {/* <iframe className="iframeLAL" src="https://infogram.com/nature-enriched-communities-1h7k230m0lk5v2x?live" layout="responsive" title="LAL Dashboard" scrolling="no" frameborder="0" style={{ border: 'none' }} allowfullscreen="allowfullscreen"></iframe> */}
            </div>
            
        </Container >
    )
}

export default Wrong
