import React from 'react'
import "./informed.css"
import { Container } from 'react-bootstrap'
// import { Row, Col } from 'react-bootstrap'
import JotformEmbed from 'react-jotform-embed';
import MetaData from "./metaData/metaData";

const metaData = {
	title: "Newsletter | Life After Life - Stay Informed", 
	description: "Stay up-to-date with the latest news and updates from Life After Life by subscribing to our newsletter. Our newsletter is packed with information on sustainability, environmental conservation, legacy and end-of-life planning, and community building. By signing up, you'll receive exclusive access to our events, promotions, and blog articles. Our team is committed to providing valuable and engaging content that educates and inspires. We believe that deathcare can be a meaningful and transformative experience, and we're dedicated to sharing our knowledge and expertise with our subscribers. Join our community of like-minded individuals who share a passion for community betterment. Sign up for our newsletter now and stay connected with LAL’s Parks.", 
	author: "Life After Life", 
	type: "website", 
	image: "/images/Life After Life Logo Blended 6 Large size smaller file.webp", 
	url: "lifeafter.life/informed"
}

function Informed() {
    return (
       
            <Container className='informedPage'>
                <MetaData {... metaData}/>
                <h1 className="inform-title">Stay Informed with Life After Life</h1>

                <div className="informedFormBundle">
                    <JotformEmbed src="https://form.jotform.com/221567088457163" />
                </div>
            </Container>
     


    )
}

export default Informed;